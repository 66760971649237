import { Text, Tooltip, Input, Field, Combobox, Option, Persona } from "@fluentui/react-components";
import React from "react";
import {
  ConfirmResetToDefault,
  GenericApiFailMessage,
  RequiredFieldErrorMessage,
} from "../../../constants/textLabels";
import ResizableTextEditor from "../../common/resizable-text-editor/resizable-text-editor";
import ISiztungFormBaseProps from "./sitzung-form-base-props";
import { getAllMitglieders } from "../../../api/konfig-mitglieder-api";
import { StatusCodes } from "http-status-codes";
import { sortMitglieder } from "../../../Helpers/mitgliederSortHelper";
import { getBrvUrl } from "../../../api/brv-token-api";
import IGraphUser from "../../../models/graph-user";
import IEinladung from "../../../models/einladung/einladung";
import { cloneDeep } from "lodash";
import ISitzungFormBaseState from "./sitzung-form-base-state";
import {
  deleteAddress,
  getAddresses,
  getInvitation,
  getInvitationById,
  patchInvitation,
  postAddress,
  postInvitation,
  putAddress,
  putInvitation,
} from "../../../api/invitations-dto-api";
import { getAllEinladungKonfig, patchProtokollSitzung } from "../../../api/konfiguration-api";
import { Guid } from "guid-typescript";
import { IMeetingFormBaseFunctions } from "./sitzung-form-base-functions";
import IAddressaten from "../../../models/einladung/addressaten";
import { AxiosResponse } from "axios";
import { MeetingFormStepStatus } from "../../../enums/meeting-form-step-status.enum";
import { getMeeting } from "../../../api/meeting-dto-api";
import { userHasPermission } from "../../../Helpers/permissionHelper";
import ConfirmDialog from "../../common/dialog/confirm-dialog";
import { handleTokenAccessFailure } from "../../../Helpers/apiHelper";
import IMitgliederGremium from "../../../models/konfiguration/gremium-mitglieder";
import InfoBox from "../../common/info-box/infoBox";
import ResetIconButton from "../../common/resetIcon/ResetIcon";
import IRoles from "../../../models/einladung/roles";
import { patchSitzungDetails } from "../../../api/sitzungen-api";
import { getAttendanceList, patchAttendanceList } from "../../../api/attendance-list-dto-api";
import { getAgenda, getSitzungProtokoll, patchAgenda } from "../../../api/agenda-dto-api";
import { InfoIcon } from "../../../utils/icons";
import ResetToDefaultButton from "../../common/ResetToDefaultButton/ResetToDefaultButton";
import ApiAlertDialog from "../../common/dialog/api-alert-dialog";
import { ErrorType } from "../../../enums/errorType";

interface IInvitationsProps extends ISiztungFormBaseProps {
  allUsers: IGraphUser[];
  theme: string;
  setIsFormValid: Function;
  resetPhase: Function
}

interface IInvitationsState extends ISitzungFormBaseState {
  invitation: IEinladung;
  invitationInitial: IEinladung;
  invitationPrev: IEinladung;
  invitationIndex: number;
  typingTimer: NodeJS.Timeout | undefined;
  mitgliederOptions: IMitgliederGremium[];
  ersatzmitgliederOptions: IMitgliederGremium[];
  sbvOptions: IMitgliederGremium[];
  javOptions: IMitgliederGremium[];
  confirmDialogRef;
  mitgliedAndErsatz: IGraphUser[];
  recordKeeper: IGraphUser;
  meetingLeader: IGraphUser;
  showAlertDialog: boolean
}

class Invitations
  extends React.Component<IInvitationsProps, IInvitationsState>
  implements IMeetingFormBaseFunctions {
  constructor(props: any) {
    super(props);

    this.state = {
      showAlertDialog: false,
      isLoading: false,
      showLoader: false,
      invitation: {
        id: Guid.EMPTY,
        sitzungId: this.props.meetingId,
        einladungsstatus: MeetingFormStepStatus.Draft,
        subject: "",
        body: "",
        adressaten: [],
        graphEventId: ''
      },
      invitationInitial: {
        id: Guid.EMPTY,
        sitzungId: this.props.meetingId,
        einladungsstatus: MeetingFormStepStatus.Draft,
        subject: "",
        body: "",
        adressaten: [],
        graphEventId: ''
      },
      invitationPrev: {
        id: Guid.EMPTY,
        sitzungId: this.props.meetingId,
        einladungsstatus: MeetingFormStepStatus.Draft,
        subject: "",
        body: "",
        adressaten: [],
        graphEventId: ''
      },
      invitationIndex: 0,
      typingTimer: undefined,
      mitgliederOptions: [],
      ersatzmitgliederOptions: [],
      sbvOptions: [],
      javOptions: [],
      mitgliedAndErsatz: [],
      confirmDialogRef: React.createRef(),
      recordKeeper: {
        id: Guid.createEmpty(),
        displayName: '',
        isActive: false
      },
      meetingLeader: {
        id: Guid.createEmpty(),
        displayName: '',
        isActive: false
      }
    };
  }

  componentDidMount = async () => {
    this.props.setIsFormValid(true)
    this.props.setIsLoading(true);
    await this.loadData();
    this.props.setSaveAndResetFunctions(this.saveDraft);
    //this.props.setDataHasChanged(false);
    await this.props.loadMeetingStepStatuses();
    this.props.setIsLoading(false);
  };

  loadData = async () => {
    const gremiumId = localStorage.getItem("selectedAuschuss")!
    const getInvitationResponse = await getInvitation(this.props.meetingId);
    let allAddresssesResponse = await getAddresses(this.props.meetingId, handleTokenAccessFailure);
    const getInvitationConfigResponse = await getAllEinladungKonfig(handleTokenAccessFailure);
    const response = await getAllMitglieders(handleTokenAccessFailure);
    const boardMembers = response.data.filter((m) => m.gremiumId === gremiumId)
    const getMeetingDetailsResponse = await getMeeting(this.props.meetingId);


    if (
      getInvitationResponse.status === StatusCodes.OK &&
      response.status === StatusCodes.OK &&
      getMeetingDetailsResponse.status === StatusCodes.OK &&
      getInvitationConfigResponse.status === StatusCodes.OK
    ) {
      const sitzungsformat = getMeetingDetailsResponse.data.sitzungsformat;

      let saveInvitationState = false;

      let invitation: IEinladung;
      const mitgliederOptions: IMitgliederGremium[] = boardMembers.filter(
        (m) => m.userType == 1
      );
      const ersatzmitgliederOptions: IMitgliederGremium[] = boardMembers.filter(
        (m) => m.userType == 2
      );
      const javOptions: IMitgliederGremium[] = boardMembers.filter(
        (m) => m.userType == 3
      );
      const sbvOptions: IMitgliederGremium[] = boardMembers.filter(
        (m) => m.userType == 4
      );


      if (getInvitationResponse.data.length > 0) {
        const getInvitation = await getInvitationById(getInvitationResponse.data[0].id, handleTokenAccessFailure);
        let invitationArray = getInvitationResponse.data;
        invitationArray.sort((invFirst, invSecond) => {
          const createdOnFirst = new Date(invFirst.createdOn);
          const createdOnSecond = new Date(invSecond.createdOn);
          return createdOnSecond.getTime() - createdOnFirst.getTime();
        });
        const tempinvitation = getInvitation.data
        // const tempinvitation = getInvitationConfigResponse.data.find(
        //   (item) => item.sitzungsformat == sitzungsformat
        // );

        invitation = invitationArray.find((inv: IEinladung) => inv.sitzungId == this.props.meetingId);
        if (!invitation) {
          tempinvitation.sitzungId = this.props.meetingId;
          tempinvitation.einladungsstatus = MeetingFormStepStatus.ReadyForProcessing;
          const postInvitationResponse = await postInvitation(tempinvitation);
          if (postInvitationResponse.status == StatusCodes.OK) {
            invitation = postInvitationResponse.data;
          }
        }

        const getBoardMembersResponse = allAddresssesResponse.data.filter((m) => m.einladungId === getInvitationResponse.data[0].id)
        invitation.adressaten = getBoardMembersResponse;
        invitation.body = tempinvitation.body
        invitation.subject = tempinvitation.subject
        saveInvitationState = true;
      } else {
        invitation = getInvitationConfigResponse.data.find(
          (item) => item.sitzungsformat == sitzungsformat
        );
        invitation.sitzungId = this.props.meetingId;
        invitation.einladungsstatus = MeetingFormStepStatus.ReadyForProcessing;
        const postInvitationResponse = await postInvitation(invitation);
        if (postInvitationResponse.status == StatusCodes.OK) {
          invitation = postInvitationResponse.data;

          let configAddresses = sortMitglieder(boardMembers);

          let boardMembersTemp: IAddressaten[] = []


          configAddresses.filter((f) => f.autoInvite === true).forEach((m) => {

            const roles: IRoles[] = []
            if (m.userType === 4 && boardMembersTemp.find((u) => m.graphUserId === u.userId)) {
              const findSBV = boardMembers.find((u) => m.graphUserId === u.graphUserId && u.userType === 4)
              const index = boardMembersTemp.findIndex((u) => m.graphUserId === u.userId)
              boardMembersTemp[index].roles.push({
                einladungsadressatId: Guid.createEmpty().toString(),
                order: findSBV.sequenz,
                role: findSBV.userType
              })
              return
            } else {
              roles.push({
                einladungsadressatId: Guid.createEmpty().toString(),
                order: m.sequenz,
                role: m.userType
              })
            }
            const temp: IAddressaten = {
              gesendetAm: new Date(),
              userId: m.graphUserId,
              einladungId: m.einladungId,
              roles: roles,
              declineReason: null,
              participationForm: null,
              response: null,
              responseComment: ''
            }
            boardMembersTemp.push(temp)
          });
          invitation.adressaten = boardMembersTemp;


          let promiseArray: Promise<AxiosResponse<any>>[] = [];
          if (invitation.adressaten) {
            invitation.adressaten.forEach(async (a) => {
              if (a) {
                a.einladungId = invitation.id;
                promiseArray.push(postAddress(a));
              }
            });
          }
          saveInvitationState = true;
          const postAddressesResponses = await Promise.all(promiseArray);
          postAddressesResponses.forEach((r) => {
            const index = invitation.adressaten.findIndex(
              (addr: IAddressaten) => addr.userId == r.data.userId
            );
            if (r.status == StatusCodes.OK && index != -1) {
              invitation.adressaten[index].id = r.data.id;
            } else {
              saveInvitationState = false;
            }
          });
        }
      }
      const filterMitAndErsatz: IGraphUser[] = []
      filterMitAndErsatz.push({
        id: Guid.createEmpty(),
        displayName: '',
        isActive: false
      })
      if (allAddresssesResponse.data.length) {
        allAddresssesResponse.data.filter((a) => {
          const findRole = a.roles.find((r) => r.role === 1 || r.role === 2)
          if (findRole) {
            const user = this.props.allUsers.find(
              (u) => u.id.toString() == a.userId
            );
            if (user) {
              filterMitAndErsatz.push(user)
            }
          }
        })
      } else {
        invitation.adressaten.filter((a) => {
          const findRole = a.roles.find((r) => r.role === 1 || r.role === 2)
          if (findRole) {
            const user = this.props.allUsers.find(
              (u) => u.id.toString() == a.userId
            );
            if (user) {
              filterMitAndErsatz.push(user)
            }
          }
        })
      }
      this.setState({ mitgliedAndErsatz: filterMitAndErsatz })

      if (getMeetingDetailsResponse.data.recordKeeper) {
        const user = this.props.allUsers.find(
          (u) => u.id.toString() == getMeetingDetailsResponse.data.recordKeeper
        );
        let userExists
        if (allAddresssesResponse.data.length) {
          userExists = allAddresssesResponse.data.find((m) => m.userId === getMeetingDetailsResponse.data.recordKeeper && m.roles.find((r) => r.role === 1 || r.role === 2))
        } else {
          userExists = invitation.adressaten.find((m) => m.userId === getMeetingDetailsResponse.data.recordKeeper && m.roles.find((r) => r.role === 1 || r.role === 2))
        }
        if (!userExists) {
          await patchSitzungDetails(this.props.meetingId, "recordKeeper", null, handleTokenAccessFailure)
          this.setState({
            recordKeeper:
            {
              id: Guid.createEmpty(),
              displayName: '',
              isActive: false
            }
          })
        } else {
          if (user) {
            this.setState({ recordKeeper: user })
          }
        }
      }
      if (getMeetingDetailsResponse.data.meetingLeader !== Guid.createEmpty() || getMeetingDetailsResponse.data.meetingLeader) {
        const user = this.props.allUsers.find(
          (u) => u.id.toString() == getMeetingDetailsResponse.data.meetingLeader
        );
        let userExists
        if (allAddresssesResponse.data.length) {
          userExists = allAddresssesResponse.data.find((m) => m.userId === getMeetingDetailsResponse.data.meetingLeader && m.roles.find((r) => r.role === 1 || r.role === 2))
        } else {
          userExists = invitation.adressaten.find((m) => m.userId === getMeetingDetailsResponse.data.recordKeeper && m.roles.find((r) => r.role === 1 || r.role === 2))
        }
        if (!userExists) {
          await patchSitzungDetails(this.props.meetingId, "meetingLeader", null, handleTokenAccessFailure)
          this.setState({
            meetingLeader:
            {
              id: Guid.createEmpty(),
              displayName: '',
              isActive: false
            }
          })
        } else {
          if (user) {
            this.setState({ meetingLeader: user })
          }
        }
      }
      if (getMeetingDetailsResponse.data.meetingLeader == null) {
        let getMeetingLeader
        if (allAddresssesResponse.data.length) {
          getMeetingLeader = allAddresssesResponse.data.find((a) => {
            const findRole = a.roles.find((r) => r.role === 1 || r.order === 0)
            if (findRole) {
              return a
            }
          })
        } else {
          getMeetingLeader = invitation.adressaten.find((a) => {
            const findRole = a.roles.find((r) => r.role === 1 || r.order === 0)
            if (findRole) {
              return a
            }
          })
        }
        if (getMeetingLeader) {
          const user = this.props.allUsers.find(
            (u) => u.id.toString() == getMeetingLeader.graphUserId || u.id.toString() == getMeetingLeader.userId
          );
          if (user) {
            this.setState({ meetingLeader: user })
            if (!getMeetingDetailsResponse.data.meetingLeader) {
              await patchSitzungDetails(this.props.meetingId, "meetingLeader", user.id, handleTokenAccessFailure)
            }
          }
        }
      }


      if (invitation.adressaten
        ?.filter((m) => m.roles.find((r) => r.role === 1)).length === 0) {
        this.props.setIsFormValid(false)
      } else {
        this.props.setIsFormValid(true)
      }

      if (saveInvitationState) {
        this.setState({
          invitation: invitation,
          invitationInitial: cloneDeep(invitation),
          invitationPrev: cloneDeep(invitation),
          mitgliederOptions: mitgliederOptions,
          ersatzmitgliederOptions: ersatzmitgliederOptions,
          javOptions: javOptions,
          sbvOptions: sbvOptions,
        })
      }
    }

  };

  openSitzvergaberechner = async () => {
    const response = await getBrvUrl(window.alert);
    if (response.status === StatusCodes.OK && response.data) {
      const url = response.data;
      window.open(url, "_blank");
    }
  };

  onMitgliederChange = (e, v) => {
    this.onBoardMemberChange(v, 1);
  };

  onErsatzmitgliederChange = (e, v) => {
    this.onBoardMemberChange(v, 2);
  };

  onJavChange = (e, v) => {
    this.onBoardMemberChange(v, 3);
  }

  onSbvChange = (e, v) => {
    this.onBoardMemberChange(v, 4);
  }

  onBoardMemberChange = async (v, mitgliedTyp: number) => {
    this.props.setDataHasChanged(true)

    const user = this.props.allUsers.find((u) => u.displayName == v.optionValue);
    const allOptions = [...this.state.mitgliederOptions, ...this.state.ersatzmitgliederOptions, ...this.state.javOptions, ...this.state.sbvOptions];
    const sequenz = allOptions.find((opt) => opt.graphUserId === user?.id.toString() && opt.userType === mitgliedTyp)?.sequenz;
    const adressaten = this.state.invitation.adressaten.find((ad) => ad.userId === user?.id.toString())

    let invitation = this.state.invitation;
    let invitationPrev = cloneDeep(invitation);

    let address: IAddressaten


    if (v.selectedOptions.find((u) => u === v.optionValue)) {
      //Adding a member
      const invitationUserIndex = invitation.adressaten.findIndex((u) => u.userId === user?.id.toString())
      if (mitgliedTyp === 1 && sequenz === 0 && user) {
        this.setState({ meetingLeader: user })
        await patchSitzungDetails(this.props.meetingId, "meetingLeader", user.id, handleTokenAccessFailure)
      }

      if (invitationUserIndex !== -1) {
        address = invitation.adressaten[invitationUserIndex]
        const role = {
          einladungsadressatId: Guid.createEmpty().toString(),
          role: mitgliedTyp,
          order: sequenz || 0
        }
        address.roles.push(role)
      } else {
        const newMitglieder: IAddressaten = {
          gesendetAm: new Date(),
          declineReason: null,
          participationForm: null,
          response: null,
          responseComment: '',
          id: adressaten?.id || Guid.createEmpty().toString(),
          userId: user!.id.toString(),
          einladungId: this.state.invitation.id,
          roles: [{
            einladungsadressatId: Guid.createEmpty().toString(),
            order: sequenz || 0,
            role: mitgliedTyp
          }]
        };
        invitation.adressaten.push(newMitglieder);
        address = newMitglieder
      }
    } else {
      //Removing a member
      const userAddressaten = invitation.adressaten.find((u) => u.userId === user!.id.toString())
      const userIndex = invitation.adressaten.findIndex((u) => u.userId === user!.id.toString())
      if (userAddressaten) {
        if (userAddressaten.roles.length > 1) {
          const newRoles: IRoles[] = userAddressaten.roles.filter((r) => r.role !== mitgliedTyp)
          const inv = invitation.adressaten[userIndex]
          inv.roles = newRoles
          address = inv as IAddressaten
          const newInvitation = this.state.invitation
          newInvitation.adressaten[userIndex].roles = newRoles
          this.setState({invitation: newInvitation})
        } else {
          address = invitationPrev.adressaten.find(
            (invPrevAddr) => invPrevAddr.userId === userAddressaten.userId
          ) as IAddressaten;
          address.userId = ''
          invitation.adressaten = invitation.adressaten.filter(
            (a) => a.userId !== user?.id.toString()
          );
        }
      }
    }
    this.resetToPhaseOne()
    this.setState(
      {
        invitation: invitation,
        invitationPrev: invitationPrev,
      }, () => this.saveDraft(mitgliedTyp, address)
    );
  }

  onSubjectChange = (e) => {
    let invitation = this.state.invitation;
    let invitationPrev = this.state.invitationPrev;
    const subject = e.target.value;
    const subjectPrev = invitation.subject;
    invitation.subject = subject;

    if (!this.state.typingTimer) {
      invitationPrev.subject = subjectPrev;
      this.setState({ invitationPrev: invitationPrev });
    }
    this.props.setDataHasChanged(true)
    this.setState({ invitation: invitation }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          if (this.isFormValid("subject")) {
            await patchInvitation(
              this.state.invitation.id,
              "subject",
              subject
            );
            this.resetToPhaseOne()
          }
        }, 1000),
      });
    });
  };

  onBodyChange = (content) => {
    let invitation = this.state.invitation;
    let invitationPrev = this.state.invitationPrev;
    const body = content
    const bodyPrev = invitation.body;

    if (!this.state.typingTimer) {
      invitationPrev.body = bodyPrev;
      this.setState({ invitationPrev: invitationPrev });
    }
    invitation.body = body;
    this.props.setDataHasChanged(true)
    this.setState({ invitation: invitation }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          if (this.isFormValid("body")) {
            await patchInvitation(
              this.state.invitation.id,
              "body",
              body
            );
            this.resetToPhaseOne()
          }
        }, 1000),
      });
    });
  };

  // setDataHasChanged = () => {
  //   if (isEqual(this.state.invitation, this.state.invitationInitial)) {
  //     this.props.setDataHasChanged(false);
  //   } else {
  //     this.props.setDataHasChanged(true);
  //   }
  // };

  isFormValid = (field: string = "") => {
    switch (field) {
      case "subject": {
        return this.state.invitation.subject != "";
      }
      case "body": {
        return this.state.invitation.body != "";
      }
      default: {
        return (
          this.state.invitation.subject != "" &&
          this.state.invitation.body != ""
        );
      }
    }
  };

  isFormDisabled(): boolean {
    const ret = !userHasPermission(
      this.props.userPermissions,
      "EinladungUpdateSend"
    )
      ||
      this.props.meetingHasStarted() || (this.props.meetingCompleted ? true : false)

    return ret;
  }

  getUserRole = (mitglieder) => {
    const first = mitglieder.roles.find((r) => r.order === 0 && r.role === 1)
    const second = mitglieder.roles.find((r) => r.order === 1 && r.role === 1)
    if (first) {
      return 'Vorsitzender'
    }
    if (second) {
      return 'Stellv. Vorsitzender'
    }
  }

  showSelectedUsers = (mitgliderType, typeNumber) => {
    if (mitgliderType)
      return <div style={{ marginTop: 5 }}>
        {mitgliderType.map(
          (m) =>
            this.props.allUsers.find(
              (u) => u.id.toString() == m.userId
            )
        ).length ? (
          this.state.invitation.adressaten
            ?.filter((m) => m.roles.some((role) => role.role === typeNumber)).sort((m1, m2) => { return m1.roles.find((r) => r.role === typeNumber)!.order - m2.roles.find((r) => r.role === typeNumber)!.order })
            .map(
              (m, i) => {
                const user = this.props.allUsers.find(
                  (u) => u.id.toString() == m.userId
                )
                if (user) {
                  return <Persona
                    key={i}
                    style={{ marginRight: 10 }}
                    name={user.displayName}
                    secondaryText={<div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{user.isActive ? '' : 'Inaktiv'}</span>
                      <span>{typeNumber === 1 && this.getUserRole(m)}</span>
                    </div>}

                  >
                  </Persona>
                }
              }
            )
        ) : null}
      </div>
  }

  saveDraft = async (
    propName: string | undefined | number = undefined,
    propValue: any = undefined,
    showLoader: boolean = false
  ) => {
    if (showLoader) { this.props.setIsLoading(true); }
    if (this.state.typingTimer) {
      clearTimeout(this.state.typingTimer);
      this.setState({ typingTimer: undefined });
    }
    let response;

    if (
      propName != undefined &&
      propValue != undefined &&
      this.props.meetingId != Guid.EMPTY
    ) {
      if (propName === 1 || propName === 2 || propName === 3 || propName === 4) {
        const address = propValue as IAddressaten;
        const userIndex = this.state.invitation.adressaten.findIndex((i) => i.userId === address.userId)
        const userIndexPrev = this.state.invitationPrev.adressaten.findIndex((i) => i.userId === address.userId)
        let method
        if (this.state.invitation.adressaten > this.state.invitationPrev.adressaten) {
          method = 'post'
        } else if (this.state.invitation.adressaten < this.state.invitationPrev.adressaten) {
          method = 'delete'
        } else {
          method = this.state.invitationPrev.adressaten[userIndexPrev].roles.length >
            this.state.invitation.adressaten[userIndex].roles.length
            ? "delete role"
            : "put role";
        }
        if (method == "delete") {
          response = await deleteAddress(address.id!);
        } else if (method == "post") {
          response = await postAddress(address);
          const addressId = response.data.id;
          const userId = response.data.userId;
          let index = this.state.invitation.adressaten.findIndex(
            (addr) => addr.userId == userId
          );
          if (index != -1) {
            let invitation = this.state.invitation;
            invitation.adressaten[index].id = addressId;
            this.setState({ invitation: invitation });
          } else {
            response.status =
              response.status == StatusCodes.OK
                ? StatusCodes.INTERNAL_SERVER_ERROR
                : response.status;
          }
        }
        const user = this.state.invitation.adressaten.find((i) => i.userId === propValue.userId)
        if (method === 'delete role') {
          const filterRoles = user?.roles.filter((r) => r.role !== propName)
          const invitation = this.state.invitation
          invitation.adressaten[userIndex].roles = filterRoles!
          response = await putAddress(address.id!, address);
        } else if (method === 'put role') {
          response = await putAddress(address.id!, address);
        }
      } else {
        response = await patchInvitation(
          this.state.invitation.id,
          propName as string,
          propValue
        );
      }
    } else {
      response = await putInvitation(this.state.invitation);
      let promiseArray: Promise<AxiosResponse<any>>[] = [];
      const currentAddressesResponse = await getAddresses(this.props.meetingId);

      if (currentAddressesResponse.status == StatusCodes.OK) {
        const currentAddresses: IAddressaten[] = currentAddressesResponse.data;
        currentAddresses.forEach((currAddr) => {
          if (
            !this.state.invitation.adressaten.find(
              (addr) => currAddr.id == addr.id
            )
          ) {
            promiseArray.push(deleteAddress(currAddr.id!));
          }
        });
        this.state.invitation.adressaten.forEach((addr) => {
          if (!currentAddresses.find((currAddr) => addr.id == currAddr.id)) {
            promiseArray.push(postAddress(addr));
          }
        });
      } else {
        response = currentAddressesResponse;
      }

      const addressResponses = await Promise.all(promiseArray);
      addressResponses.forEach((r) => {
        if (r.data) {
          const index = this.state.invitation.adressaten.findIndex(
            (addr) => addr.userId == r.data.userId
          );
          if (index != -1) {
            let createdAddress = this.state.invitation.adressaten[index];
            createdAddress.id = r.data.id;
          } else {
            r.status = StatusCodes.INTERNAL_SERVER_ERROR;
          }
        }
        if (r.status != StatusCodes.OK) {
          response = r;
        }
      });
    }

    if (this.state.invitation.adressaten
      ?.filter((m) => m.roles.find((r) => r.role === 1)).length === 0) {
      this.props.setIsFormValid(false)
    } else {
      this.props.setIsFormValid(true)
    }

    if (showLoader) { this.props.setIsLoading(false); }
    this.loadData()
    if (response.status < 299) {
      return true;
    } else {
      this.setState({ invitation: cloneDeep(this.state.invitationPrev), showAlertDialog: true });
      return false;
    }
  };

  confirmErsatzmitgliederChange = (e, v) => {
    let valueIsAdded = v.selectedOptions.filter(opt => typeof (opt) === "string")
    this.props.setDataHasChanged(true)
    if (valueIsAdded.length) {
      this.state.confirmDialogRef.current.showConfirmDialog("Sind Sie sicher, dass Sie dieses Ersatzmitglied einladen möchten?", () => this.onErsatzmitgliederChange(e, v));
    } else {
      this.onErsatzmitgliederChange(e, v);
    }
    this.resetToPhaseOne()
  }

  // reset = async (showLoader: boolean = false) => {
  //   const invitation = cloneDeep(this.state.invitationInitial);
  //   const invitationPrev = cloneDeep(this.state.invitation);
  //   await this.setState({
  //     invitation: invitation,
  //     invitationPrev: invitationPrev,
  //   });
  //   const result = await this.saveDraft(undefined, undefined, showLoader);
  //   return result;
  // };

  resetToDefaultSubject = async () => {
    this.state.confirmDialogRef.current.showConfirmDialog(ConfirmResetToDefault, async () => {
      const resp = await getAllEinladungKonfig(handleTokenAccessFailure)
      const respMeeting = await getMeeting(this.props.meetingId, handleTokenAccessFailure)
      const findInv = resp.data.find((inv) => inv.sitzungsformat === respMeeting.data.sitzungsformat)
      await patchInvitation(
        this.state.invitation.id,
        'subject',
        findInv.subject
      );
      this.setState((prevState) => ({
        invitation: { ...prevState.invitation, subject: findInv.subject },
      }));
    })
    this.resetToPhaseOne()
  }

  resetToDefaultBody = async () => {
    this.state.confirmDialogRef.current.showConfirmDialog(ConfirmResetToDefault, async () => {
      const resp = await getAllEinladungKonfig(handleTokenAccessFailure)
      const respMeeting = await getMeeting(this.props.meetingId, handleTokenAccessFailure)
      const findInv = resp.data.find((inv) => inv.sitzungsformat === respMeeting.data.sitzungsformat)
      await patchInvitation(
        this.state.invitation.id,
        'body',
        findInv.body
      );
      this.setState((prevState) => ({
        invitation: { ...prevState.invitation, body: findInv.body },
      }));
    })
    this.resetToPhaseOne()
  }

  onMeetingLeaderChange = async (e, v) => {
    const user = this.props.allUsers.find(
      (u) => u.id.toString() == v.optionValue
    );
    if (user) {
      this.setState({ meetingLeader: user })
    } else {
      this.setState({
        meetingLeader: {
          id: Guid.createEmpty(),
          displayName: '',
          isActive: false
        }
      })
    }
    await patchSitzungDetails(this.props.meetingId, "meetingLeader", v.optionValue, handleTokenAccessFailure)
    this.resetToPhaseOne()
  }

  onRecordKeeperChange = async (e, v) => {
    const user = this.props.allUsers.find(
      (u) => u.id.toString() == v.optionValue
    );
    if (user) {
      this.setState({ recordKeeper: user })
    } else {
      this.setState({
        recordKeeper: {
          id: Guid.createEmpty(),
          displayName: '',
          isActive: false
        }
      })
    }

    await patchSitzungDetails(this.props.meetingId, "recordKeeper", v.optionValue, handleTokenAccessFailure)
    this.resetToPhaseOne()
  }

  resetToPhaseOne = async () => {
    const getAttendanceListResponse = await getAttendanceList(
      this.props.meetingId, handleTokenAccessFailure
    );
    const { data } = await getSitzungProtokoll(this.props.meetingId);
    const agenda = await getAgenda(this.props.meetingId)
    if (getAttendanceListResponse.data.length > 0) {
      await patchAttendanceList(getAttendanceListResponse.data[0].id, 'anwesenheitslistenstatus', MeetingFormStepStatus.Draft);
    }
    if (this.state.invitation.id) {
      await patchInvitation(this.state.invitation.id, 'einladungsstatus', MeetingFormStepStatus.ReadyForProcessing, handleTokenAccessFailure)
    }
    if (agenda.data.length > 0) {
      await patchAgenda(agenda.data[0].id, 'tagesordnungstatus', MeetingFormStepStatus.ReadyForProcessing);
    }
    if (data.length > 0) {
      await patchProtokollSitzung('protokollstatus', MeetingFormStepStatus.NeedsReprocessing, data[0].id, handleTokenAccessFailure)
    }
    this.props.resetPhase()
  }

  getUserDisplayName = (user) => {
    if (user.displayName) {
      if (user.isActive) {
        return user.displayName
      } else {
        return user.displayName + ' (Inaktiv)'
      }
    }
    return ''
  }

  renderComboBox = (type, userOptions) => {
    return <Combobox
      multiselect={true}
      disabled={this.isFormDisabled()}
      className="combobox-style"
      placeholder="Suche nach Mitglied"
      required={type === 1}
      selectedOptions={this.state.invitation?.adressaten
        ?.filter((m) => m.roles.some((r) => r.role === type))
        .sort((m1, m2) => {
          let sequenz1 = userOptions.find(opt => opt.id == m1.id)?.sequnez;
          let sequenz2 = userOptions.find(opt => opt.id == m2.id)?.sequenz;

          if (sequenz1 == undefined) { sequenz1 = 999; }
          if (sequenz2 == undefined) { sequenz2 = 999; }

          return sequenz1 - sequenz2;
        })
        .map(
          (m) => {
            return this.props.allUsers.find(
              (u) => u.id.toString() == m.userId
            )?.displayName!
          }
        )}
      onOptionSelect={(e, v) => {
        this.onBoardMemberChange(v, type)
      }
      }
    >{
        userOptions.sort((m1, m2) => {
          return m1.sequenz - m2.sequenz;
        })
          .map(
            (s) => {
              const user = this.props.allUsers.find(
                (u) => u.id.toString() == s.graphUserId
              );
              if (user) {
                return <Option key={user?.id.toString()} value={user?.displayName}>{user?.isActive ? user.displayName : `${user.displayName} (Inaktiv)`}</Option>
              }
            }
          )
      }</Combobox>
  }

  render() {
    return (
      <>
        {
          <div className="invitaions__wrapper">
            <ConfirmDialog ref={this.state.confirmDialogRef} />
            <ApiAlertDialog
              message={GenericApiFailMessage}
              errorType={ErrorType.Error}
              isDialogHidden={!this.state.showAlertDialog}
              closeFunction={() => { this.setState({ showAlertDialog: false }) }} />
            <InfoBox label={"Wählen Sie hier aus, wen Sie einladen möchten. Die festen Mitglieder sind automatisch hinterlegt. Für den E-Mail-Text wird die in der Konfiguration hinterlegte Textvorlage verwendet. Sie können ihn aber für jede Sitzung individuell anpassen. Achtung: Die {{PLATZHALTER}} sollten nicht verändert werden, damit sie automatisch eingefügt werden. Auch der E-Mail-Betreff generiert sich von selbst aus den Platzhaltern, kann aber ebenfalls verändert werden. Beim richtigen Nachladen zur Betriebsratssitzung hilft Ihnen der Link zu unserer BRV-Software."}></InfoBox>
            <div className="title-margin">
              <Text className="sitzung-form__section-title" as="h3">
                Einladen und Nachladen
              </Text>
            </div>
            <Field
              label="Mitglieder"
              validationMessage={this.state.invitation.adressaten
                ?.filter((m) => m.roles.find((r) => r.role === 1)).length === 0 ? RequiredFieldErrorMessage : ''}
              validationState={this.state.invitation.adressaten
                ?.filter((m) => m.roles.find((r) => r.role === 1)).length === 0 ? 'error' : 'none'}
              className="field-style">
              {this.renderComboBox(1, this.state.mitgliederOptions)}
              {this.showSelectedUsers(this.state.invitation.adressaten
                ?.filter((m) => m.roles.find((r) => r.role === 1)), 1)}
            </Field>
            <div style={{ display: "flex", gap: 10 }}>
              <Field style={{ flex: 1 }} label={"Sitzungsleitung"}>
                <Combobox
                  className="combobox-style-width"
                  disabled={this.isFormDisabled()}
                  value={this.getUserDisplayName(this.state.meetingLeader)}
                  onOptionSelect={this.onMeetingLeaderChange}
                  placeholder="Suche nach Mitglied">
                  {this.state.mitgliedAndErsatz.map(
                    (m, i) => <Option key={m?.id.toString()} value={m?.id.toString()}>{m.displayName === '' ? '' : m?.isActive ? m.displayName : `${m.displayName} (Inaktiv)`}</Option>
                  )}
                </Combobox>
              </Field>
              <Field style={{ flex: 1 }} label={"Protokollführer"}>
                <Combobox
                  className="combobox-style-width"
                  disabled={this.isFormDisabled()}
                  value={this.getUserDisplayName(this.state.recordKeeper)}
                  onOptionSelect={this.onRecordKeeperChange}
                  placeholder="Suche nach Mitglied">
                  {this.state.mitgliedAndErsatz.map(
                    (m, i) => <Option key={m?.id.toString()} value={m?.id.toString()}>{m.displayName === '' ? '' : m?.isActive ? m.displayName : `${m.displayName} (Inaktiv)`}</Option>
                  )}
                </Combobox>
              </Field></div>
            <Field
              className="field-style"
              label={<>Ersatzmitglieder{" "}
                <Tooltip
                  relationship="label"
                  content='Hier geht`s zu unserer bewährten BRV-Software exklusiv für mein ifb-Nutzer. Um immer das richtige Ersatzmitglied zu finden, dient das Modul "Ersatzmitglieder". Voraussetzung ist die einmalige Eingabe der Ergebnisse Ihrer letzten BR-Wahl im Modul "Sitzvergaberechner".'
                ><a
                  onClick={this.openSitzvergaberechner}
                  className="link__open-brv"
                >
                    Unsicher, welches Ersatzmitglied bei Verhinderung nachrückt?
                  </a></Tooltip></>}>
              {this.renderComboBox(2, this.state.ersatzmitgliederOptions)}
              {this.showSelectedUsers(this.state.invitation.adressaten
                ?.filter((m) => m.roles.find((r) => r.role === 2)), 2)}
            </Field>
            <Field
              className="field-style"
              label={<>JAV</>}>
              {this.renderComboBox(3, this.state.javOptions)}
              {this.showSelectedUsers(this.state.invitation.adressaten
                ?.filter((m) => m.roles.find((r) => r.role === 3)), 3)}
            </Field>
            <Field
              className="field-style"
              label={<>SBV</>}>
              {this.renderComboBox(4, this.state.sbvOptions)}
              {this.showSelectedUsers(this.state.invitation.adressaten
                ?.filter((m) => m.roles.find((r) => r.role === 4)), 4)}
            </Field>
            <div style={{ marginTop: 15 }}>
              <Text className="sitzung-form__section-subtitle" as="h4">
                Einladungstext
              </Text>
            </div>
            <Field
              className="field-style"
              validationMessage={!this.isFormValid("subject") ? RequiredFieldErrorMessage : ""}
              validationState={!this.isFormValid("subject") ? "error" : "none"}
              label={<>Betreff der E-Mail{" "}
                <Tooltip
                  relationship="label"
                  content="Der Betreff der E-Mail generiert sich von selbst aus den Sitzungsdetails. Wenn Sie einen anderen Betreff nutzen möchten, können Sie ihn auch beliebig überschreiben."
                ><span><InfoIcon /></span></Tooltip>
                {<ResetIconButton disabled={this.isFormDisabled()} onClick={this.resetToDefaultSubject} />}</>}>
              <Input
                value={this.state.invitation?.subject}
                onChange={this.onSubjectChange}
                style={{
                  width: "100%",
                  margin: "0 0 0!important 0",
                }}
                disabled={this.isFormDisabled()}
              />
            </Field>
            <Field
              className="field-style"
              validationMessage={!this.isFormValid("body") ? RequiredFieldErrorMessage : ""}
              validationState={!this.isFormValid("body") ? "error" : "none"}
              label={<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>E-Mail Text  <ResetToDefaultButton disabledState={this.isFormDisabled()} onClick={this.resetToDefaultBody} /></div>}>
              <ResizableTextEditor
                theme={this.props.theme}
                onMailBodyChange={this.onBodyChange}
                bodyValue={this.state.invitation.body}
                disabled={this.isFormDisabled()} />
            </Field>
          </div>
        }
      </>
    );
  }
}

export default Invitations;
