import React from 'react'
import './stepper.scss'

const Stepper = ({ steps, activeStep }) => {
    return (
        <div className='stepper-container'>
            {steps.map((step, i) => {
                return <div key={i} className='step'>
                    <div className={`${activeStep === (i + 1) ? 'step-number step-number-active' : 'step-number'}`}>
                        {i + 1}
                    </div>
                    <div className={`${activeStep === (i + 1) ? 'step-text-active' : ''}`}>
                        {step}
                    </div>
                    {steps.length !== (i + 1) &&
                        <span className='step-line'></span>
                    }
                </div>
            })}
        </div>
    )
}

export default Stepper