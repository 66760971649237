import { tokens } from "@fluentui/react-components"
import "./info-box-style.scss"
import { InfoIcon } from "../../../utils/icons"

const InfoBox = ({ label }) => {
    return (
        <div
            className='info-box'
        >
            <span style={{ fontSize: "40px", color: tokens.colorNeutralForeground3 }}
            ><InfoIcon /></span><span className='info-box-label'>{label}</span></div>
    )
}

export default InfoBox