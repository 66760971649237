import { Button, Dialog, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { CloseIcon } from "../../../utils/icons";
const KonfigurationSpeichernModal = ({
  isSavingDialogOpen,
  handleCloseDialog,
  handleSaveAndContinueDialog,
  handleContinueDialog,
  saveData,
}) => {
  const buttonStyle = {
    height: "50px",
    width: "100px",
  };

  return (
    <Dialog
      open={isSavingDialogOpen}>
      <DialogSurface backdrop={<div style={{ backgroundColor: "rgba( 0, 0, 0, 0.8)", width: "100vw", height: "100vh" }}>
      </div>}>
        <DialogTitle style={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={handleCloseDialog} appearance="transparent" icon={<span><CloseIcon /></span>} ></Button>
        </DialogTitle>
        <DialogContent>
          <div className="Konfiguration-admin-delete-modal">
            <span style={{ fontSize: "1.7rem", fontWeight: "500" }}>
              Nicht gespeicherte Daten gehen verloren. Wie möchten Sie fortfahren?
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "13%",
              }}
            >
              <Button
                appearance="primary"
                style={buttonStyle}
                onClick={async () => {
                  await saveData();
                  handleSaveAndContinueDialog();
                }}
              >
                Speichern
              </Button>
              <Button style={buttonStyle} onClick={() => handleContinueDialog()}>
                Nicht speichern
              </Button>
              <Button
                style={{ ...buttonStyle, backgroundColor: "red", color: "white" }}
                onClick={() => handleCloseDialog()}
              >
                Abbrechen
              </Button></div></div>
        </DialogContent>
      </DialogSurface>
    </Dialog>
  );
};

export default KonfigurationSpeichernModal;

