import * as React from "react";
import { Text } from "@fluentui/react-components";
import { ShieldIcon } from "../utils/icons";

class ErrorPage extends React.Component {

    render = () => {
        return (
            <div className="container-div">
                <div className="container-subdiv">
                    <div className="error-message">
                        <ShieldIcon />
                        <Text as="h1" weight='semibold' >Irgendwas ist schiefgelaufen. Versuchen Sie es in ein paar Minuten wieder. Wenn das Problem anhält, wenden Sie sich an Ihren IT-Administrator.</Text>
                    </div>
                </div>
            </div>
        );
    }
};

export default ErrorPage;