import { getGlobalClassNames } from "@fluentui/react";
import type {
  IPaginationStyles,
  IPaginationStyleProps,
} from "./Pagination.types";
import { IStyle } from "@fluentui/react";

const GlobalClassNames = {
  root: "ms-Pagination-container",
  pageNumber: "ms-Pagination-pageNumber",
};

export function getStyles(props: IPaginationStyleProps): IPaginationStyles {
  const { theme, format, appTheme } = props;
  const { palette } = theme;
  const classNames = getGlobalClassNames(GlobalClassNames, theme);
  const buttonStyles: IStyle = {
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
  };

  return {
    root: [
      classNames.root,
      format === "buttons" && {
        listStyle: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    ],
    previousNextPage: [
      {
        color: palette.themePrimary,
      },
    ],
    previousNextPageDisabled: [
      {
        cursor: "default",
        backgroundColor: "transparent",
      },
    ],
    pageNumber: [
      classNames.pageNumber,
      buttonStyles,
      {
        verticalAlign: "middle",
        minWidth: "32px",
        minHeight: "32px",
        color:
          appTheme === "default" ? theme.palette.black : theme.palette.white,
        selectors: {
          "&[aria-checked=true]": {
            color:
              appTheme === "default"
                ? theme.palette.black
                : theme.palette.white,
            cursor: "default",
            fontWeight: "bold",
          },
          "&:hover[aria-checked=true]": {
            color:
              appTheme === "default"
                ? theme.palette.black
                : theme.palette.white,
            backgroundColor: "transparent",
          },
          ":active": {
            backgroundColor: "transparent",
          },
        },
      },
    ],
    visibleItemLabel: [
      {
        color: palette.neutralSecondary,
      },
    ],
    comboBox: {
      maxWidth: "70px",
      display: "inline-block",
      verticalAlign: "middle",
    },
  };
}
