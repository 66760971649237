import React from "react";
import IPermission from "../../models/IPermission";
import { RegistrationState } from "../../enums/registration-state.enum";
import { AuschussContextObject } from "../../interfaces/providers/asuchuss-provider.interface";

const initialEmptyContext: {
    registrationState: RegistrationState,
    permissionsArray: IPermission[],
    auschuss: AuschussContextObject | null,
} = {
    registrationState: RegistrationState.None,
    permissionsArray: [],
    auschuss: null
}

const PermissionsContext = React.createContext<{
    registrationState: RegistrationState,
    permissionsArray: IPermission[],
    auschuss: AuschussContextObject | null
}>(initialEmptyContext);

export default PermissionsContext;