import * as React from "react";
import { Text } from "@fluentui/react-components";
import { ShieldIcon } from "../../utils/icons";

class UnauthorizedPage extends React.Component {

    render = () => {
        return (
            <div className="container-div">
                <div className="container-subdiv">
                    <div className="error-message">
                        <ShieldIcon />
                        <Text as="h1" weight='semibold' >
                            Sie haben leider keine Berechtigung, um hier zuzugreifen. Falls Sie denken, dass dies ein Fehler ist, wenden Sie sich bitte an Ihren BRV.
                        </Text>
                    </div>
                </div>
            </div>
        );
    }
};

export default UnauthorizedPage;