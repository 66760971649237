import { AxiosHeaders } from "axios";
import axios from "./axios-decorator";

export const patchTagesordnungDto = async (
  id: string,
  propName: string,
  propValue,
  handleTokenAccessFailure: (error: string) => void
) => {
  const data = [{
    "op": "replace",
    "path": "/" + propName,
    "value": propValue,
    "from": ""
  }];
  const config = {
    headers: new AxiosHeaders({
      'Content-Type': 'application/json-patch+json',
    })
  }
  let url = `api/TagesordnungDto/${id}`;
  return await axios.patch(url, handleTokenAccessFailure, data, config);
};
