import { AxiosHeaders } from "axios";
import IAnwesenheitsliste from "../models/anwesenheit/anwesenheitsliste";
import Anwesenheitslisteneintrag from "../models/anwesenheit/anwesenheitslisteneintrag";
import axios from "./axios-decorator";

export const getAttendanceList = async (meetingId: string, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/AnwesenheitslisteDto/sitzung/' + meetingId;
    return await axios.get(url, handleTokenAccessFailure);
}

export const postAttendanceList = async (attendanceList: IAnwesenheitsliste, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/AnwesenheitslisteDto';
    return await axios.post(url, handleTokenAccessFailure, attendanceList);
}

export const patchAttendanceList = async (attendanceListId: string, propName: string, propValue, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/AnwesenheitslisteDto/' + attendanceListId;
    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    }
    return await axios.patch(url, handleTokenAccessFailure, data, config);
}