import { Guid } from "guid-typescript";
import IEinladungsrückmeldungUpdate from "../models/einladungsrückmeldung/update-einladungsrückmeldung";
import axios from "./axios-browser-decorator";
import IAddressatenResponse from "../models/einladung/addressatenResponse";
import { AxiosHeaders } from "axios";

export const getUserResponse = async (accessToken: string, einladungId: Guid, gremiumId: string) => {
    let requestUrl = `/api/EinladungsadressatDto/einladung/${einladungId}`
    return await axios.get(requestUrl, accessToken, { headers: new AxiosHeaders({ GremiumId: gremiumId }) })
};

export const getEinladungsadressatDto = async (accessToken: string, sitzungId: Guid) => {
    let requestUrl = `/api/EinladungsadressatDto/sitzung/${sitzungId}/currentUser`
    return await axios.get(requestUrl, accessToken)
};

export const updateUserResponse = async (accessToken: string, id: string, rückmeldung: IAddressatenResponse, gremiumId: string) => {
    let requestUrl = `/api/EinladungsadressatDto/${id}`
    return await axios.put(requestUrl, accessToken, rückmeldung, { headers: new AxiosHeaders({ GremiumId: gremiumId }) });
};

export const postUserResponse = async (accessToken: string, rückmeldung: IEinladungsrückmeldungUpdate) => {
    let requestUrl = `/api/EinladungsadressatDto`
    return await axios.post(requestUrl, accessToken, rückmeldung);
};

export const getSitzungDetails = async (meetingId: string, accessToken) => {
    const url = '/api/SitzungDto/' + meetingId;
    return await axios.get(url, accessToken);
}

export const getAttendanceListRuckmeldung = async (meetingId: string, gremiumId, accessToken) => {
    const url = '/api/AnwesenheitslisteDto/sitzung/' + meetingId;
    return await axios.get(url, accessToken, { headers: new AxiosHeaders({ GremiumId: gremiumId }) });
}

export const patchAttendanceListRuckmeldung = async (gremiumId: string, attendanceListId: string, propName: string, propValue, accessToken) => {
    const url = '/api/AnwesenheitslisteDto/' + attendanceListId;
    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
            GremiumId: gremiumId
        })
    }
    return await axios.patch(url, accessToken, data, config);
}


export const gremiumGet = async (accessToken) => {
    let requestUrl = `/api/GremiumDetailsDto`;
    return await axios.get(requestUrl, accessToken);
}
