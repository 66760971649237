import * as React from "react";
import './dialog.scss';
import { Dialog, DialogBody, DialogSurface, DialogTitle, DialogContent, Button } from "@fluentui/react-components";

interface IAlertDialogProps {
    isDialogHidden: boolean;
    message: string;
    closeFunction: Function;
}

interface IAlertDialogState {
    dialogMessage: string;
}

export default class AlertDialog extends React.Component<IAlertDialogProps, IAlertDialogState>{
    constructor(props: any) {
        super(props);
        this.state = {
            dialogMessage: "",
        }
    }

    onConfirmClick = () => {
        this.props.closeFunction();
    }
  
    render() {
        return (
            <Dialog
                open={!this.props.isDialogHidden}
            >
                <DialogSurface>
                    <DialogTitle>{this.props.message}</DialogTitle>
                    <DialogBody>
                        <DialogContent style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            <Button appearance="primary" onClick={this.onConfirmClick}>Ok</Button>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    }
}