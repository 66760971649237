import { Guid } from "guid-typescript";
import ISitzung from "../models/sitzung/create-sitzung";
import axios from "./axios-decorator";
import { AxiosHeaders } from "axios";

export const createSitzungDraft = async (neueSitzung: ISitzung, handleTokenAccessFailure: (error: string) => void) => {
    //TODO: Implement it after the API endpoint is created
    let requestUrl = '';
    return await axios.get(requestUrl, handleTokenAccessFailure);
}

export const getSitzungenDetails = async (sitzungId: Guid | string, handleTokenAccessFailure: (error: string) => void) => {
    let requestUrl = `/api/SitzungDto/${sitzungId}`;
    return await axios.get(requestUrl, handleTokenAccessFailure);
};

export const getChannelsCount = async (teamId: string, handleTokenAccessFailure: (error: string) => void) => {
    let requestUrl = `/api/TeamDto/${teamId}/channel/count`;
    return await axios.get(requestUrl, handleTokenAccessFailure);
};

export const patchSitzungDetails = async (meetingId: string, propName: string, propValue: any, handleTokenAccessFailure: (error: string) => void) => {
    const url = '/api/SitzungDto/' + meetingId;
    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];

    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    };
    return await axios.patch(url, handleTokenAccessFailure, data, config);
}