import * as React from "react";
import { Text } from "@fluentui/react-components";
import { ShieldIcon } from "../../utils/icons";

class RegistrationUnapproved extends React.Component {

    render = () => {
        return (
            <div className="container-div">
                <div className="container-subdiv">
                    <div className="error-message">
                        <ShieldIcon/>
                        <Text weight='semibold'>Ihr Konto wird erstellt. Bitte warten Sie noch einige Minuten.</Text>
                    </div>
                </div>
            </div>
        );
    }
};

export default RegistrationUnapproved;