/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  Dropdown,
  Spinner,
  Option,
  Field,
} from "@fluentui/react-components";
import { DismissFilled } from "@fluentui/react-icons";
import { KonfigurationGremiumForm } from "../konfiguration/konfiguration-gremium-form/konfiguration-gremium-form";
import { createRef, useEffect, useState } from "react";
import {
  getAllUsersAsyncFirstPage,
  getAllUsersAsyncNextLink,
} from "../../api/users";
import { StatusCodes } from "http-status-codes";
import { getJoinedTeams } from "../../api/teams-api";
import IGremium from "../../models/konfiguration/gremium";
import { app } from "@microsoft/teams-js";
import {
  LoaderMessage,
  RequiredFieldErrorMessage,
} from "../../constants/textLabels";
import { createNewAuschuss } from "../../api/gremium-details-api";
import ITeamsTeam from "../../models/teams/teams-team";
import IGraphUser from "../../models/graph-user";
import { Guid } from "guid-typescript";
import {
  getAllGremiumKonfigs,
  getGremiumKonfig,
} from "../../api/konfiguration-api";
import IGremiumCreate from "../../models/konfiguration/gremiumCreate";
import "./GremiumDialog.scss";
import IGremiumTypes from "../../models/konfiguration/gremium-types";
import { GremiumTypes } from "../../enums/gremiumTypes.enum";
import { mergeArraysWithoutDuplicatesID } from "../../utils/utility";

const gremiumTypes: IGremiumTypes[] = [
  {
    id: 0,
    label: "Neues Betriebsratsgremium",
  },
  {
    id: 1,
    label: "Neuer Ausschuss",
  },
  {
    id: 2,
    label: "Neuer Gesamtbetriebsrat",
  },
  {
    id: 3,
    label: "Neuer Konzernbetriebsrat",
  },
];

const defaultGremiumObject: IGremiumCreate = {
  id: Guid.createEmpty().toString(),
  gremienname: "",
  anzahlMitglieder: 0,
  anzahlErsatzmitglieder: 0,
  anzahlJAV: 0,
  anzahlSBV: 0,
  assistenzUserId: Guid.createEmpty().toString(),
  hatAssistenzVollzugriff: false,
  defaultTeamId: Guid.parse(Guid.EMPTY),
  showDauerOnTagesordnung: false,
  showHasAttachmentsOnTagesordnung: false,
  showResponsiblePersonOnTagesordnung: false,
  protokollname: "",
  tagesordnungsname: "",
  parentGremiumId: null,
  ortFiliale: "",
  firmenname: "",
  brMail: "",
  anwesenheitslistenname: "",
  administratorUsers: [],
  type: GremiumTypes.Betriebsrat,
  isFirstUse: false,
};

export const GremiumDialog = ({
  setGremiumDialog,
  showGremiumDialog,
  userPermissions,
  auschuss,
}) => {
  const [adminDeleteDialogOpen, setAdminDeleteDialogOpen] = useState(false);
  const [adminAddDialogOpen, setAdminAddDialogOpen] = useState(false);
  const [administratorUserNames, setAdministratorUserNames] = useState<
    string[]
  >([]);
  const [selectedAssistants, setSelectedAssistants] = useState<string[]>([]);
  const [selectedAdministrators, setSelectedAdministrators] = useState<
    string[]
  >([]);
  const [updatedSelectedAssistents, setUpdatedSelectedAssistents] = useState<
    string[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [teams, setTeams] = useState<ITeamsTeam[]>([]);
  const [graphUsers, setGraphUsers] = useState<IGraphUser[]>([]);
  const [initialGremium, setInitialGremium] =
    useState<IGremium>(defaultGremiumObject);
  const [updatedGremium, setUpdatedGremium] =
    useState<IGremium>(defaultGremiumObject);
  const [currentLoggedUserId, setCurrentLoggedUserId] = useState("");
  const [teamDisplayName, setTeamDisplayName] = useState("");
  // const [assistentUserName, setAssistentUserName] = useState("");
  const [configDataChanged, setConfigDataChanged] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [adminDropdownRef, setAdminDropdownRef] = useState<any>(createRef());
  const [teamsContext, setTeamsContext] = useState<app.Context | null>(null);
  const [spreichenClicked, setSpreichenClicked] = useState<boolean>(false);
  const [isCreateNew, setIsCreateNew] = useState<boolean>(false);
  const [deleteAdmin, setDeleteAdmin] = useState<boolean>(false);
  const [selectedAdmin, setSelectedAdmin] = useState("")
  const [addAdmin, setAddAdmin] = useState<boolean>(false);
  const [allGremiums, setAllGremiums] = useState<IGremium[]>([]);
  const [nameExists, setNameExists] = useState<boolean>(false);
  const [selectedGremiumType, setSelectedGremiumType] = useState<IGremiumTypes>(
    gremiumTypes[0]
  );
  const [selectedGremium, setSelectedGremium] = useState<IGremium | null>();
  const [nextLink, setNextLink] = useState("");

  useEffect(() => {
    loadAllUsersAsync();
  }, [selectedGremiumType, selectedGremium]);

  useEffect(()=>{
    if(addAdmin && selectedAdmin){
      setSelectedAdministrators((prevState)=>([...prevState, selectedAdmin]));
      setAddAdmin(false)
      setSelectedAdmin("")
    }
  },[addAdmin, selectedAdmin])

  const getGremiumData = async () => {
    setIsLoading(true);
    const { data } = await getGremiumKonfig(
      auschuss.gremiumId,
      handleTokenAccessFailure
    );
    if (data) {
      setInitialGremium((prevState) => {
        return {
          ...prevState,
          firmenname: data.firmenname,
          ortFiliale: data.ortFiliale,
          parentGremiumId: null,
        };
      });
    }
  };

  const getAllGremiums = async () => {
    const response = await getAllGremiumKonfigs(handleTokenAccessFailure);
    setAllGremiums(response.data);
  };

  useEffect(() => {
    getGremiumData();
    fetchContext();
    getAllGremiums();
  }, []);

  useEffect(() => {
    if (teamsContext) {
      loadTeams();
    }
  }, [teamsContext]);

  useEffect(() => {
    updateSelectedAdministrators();
  }, [selectedAdministrators]);

  useEffect(() => {
    updateSelectedAssistants();
  }, [updatedSelectedAssistents]);

  useEffect(() => {
    if (initialGremium) {
      setCurrentLoggedUserId(currentLoggedUserId);
      populateTeamDisplayName();
      setUpdatedGremium(initialGremium);
    }
  }, [initialGremium]);

  useEffect(() => {
    if (graphUsers?.length > 0) {
      loadGremiumAsync();
    }
  }, [graphUsers]);

  const fetchContext = async () => {
    app.getContext().then((context: app.Context) => {
      setTeamsContext(context);
    });
  };

  const handleTokenAccessFailure = (error: string) => {
    alert(error);
  };

  const loadTeams = async () => {
    const response = await getJoinedTeams(handleTokenAccessFailure);
    if (response.status === StatusCodes.OK && response.data) {
      setTeams(response.data);
      await loadAllUsersAsync();
    }
  };

  const loadAllUsersAsync = async () => {
    const allMitglieders = await getAllUsersAsyncFirstPage(
      handleTokenAccessFailure,
      ""
    );
    setGraphUsers(allMitglieders?.data?.items || []);
    setNextLink(allMitglieders?.data?.nextLink || "");
    setIsLoading(false);
  };

  const loadGremiumAsync = async () => {
    const currentLoggedUserId = teamsContext?.user?.id;
    setCurrentLoggedUserId(currentLoggedUserId || "");
  };

  const populateTeamDisplayName = () => {
    const teamId = initialGremium.defaultTeamId;
    const teamDisplayName = teams.find((t) => t.id === teamId)?.displayName;
    setTeamDisplayName(teamDisplayName || "");
  };

  const configDataChange = (e) => {
    if (
      e &&
      e.target ===
        adminDropdownRef.current.querySelector(
          ".ui-dropdown__searchinput__input"
        )
    ) {
      return;
    }
    setConfigDataChanged(true);
    sessionStorage.setItem("configDataChanged", "true");
  };

  const isFormValid = () => {
    if (selectedGremiumType.id === GremiumTypes.Ausschuss && !selectedGremium) {
      return false;
    }
    return (
      !nameExists &&
      initialGremium.gremienname.trim() !== "" &&
      initialGremium.anzahlMitglieder > 0 &&
      initialGremium.firmenname.trim() !== "" &&
      initialGremium.brMail.trim() !== "" &&
      initialGremium.defaultTeamId!.toString() !=
        Guid.createEmpty().toString() &&
      administratorUserNames?.length > 0
    );
  };

  const confirmDecreasingMemberCount = () => {
    const response = window.confirm(
      "Die Verkleinerung der Gremiumsgröße kann zur automatischen Entfernung von Gremiumsmitgliedern führen!"
    );
    return response;
  };

  const onGremiennameChange = (event: any) => {
    let gremienname = event.target.value;
    const checkGremiumName = allGremiums.find(
      (g) => g.gremienname === gremienname
    );
    if (checkGremiumName) {
      setNameExists(true);
    } else {
      setNameExists(false);
    }
    setInitialGremium((prevState) => ({ ...prevState, gremienname }));
    setUpdatedGremium({ ...initialGremium, gremienname });
  };

  const onFirmenameChange = (event: any) => {
    let firmenname = event.target.value;
    setInitialGremium((prevState: any) => ({ ...prevState, firmenname }));
    setUpdatedGremium({ ...initialGremium, firmenname });
  };

  const onOrtFilialeChange = (event: any) => {
    let ortFiliale = event.target.value;
    setInitialGremium((prevState: any) => ({ ...prevState, ortFiliale }));
    setUpdatedGremium({ ...initialGremium, ortFiliale });
  };

  const onMitgliederChange = (event: any) => {
    let anzahlMitglieder: number = parseInt(event.target.value);
    let prevValue = initialGremium.anzahlMitglieder;

    if (
      anzahlMitglieder === null ||
      anzahlMitglieder < 1 ||
      Number.isNaN(anzahlMitglieder)
    ) {
      setInitialGremium((prevState: any) => ({ ...prevState, prevValue }));
      setUpdatedGremium({ ...initialGremium, anzahlMitglieder });
      return;
    }

    if (prevValue > anzahlMitglieder) {
      // if (confirmDecreasingMemberCount() === false) return;
    }

    setInitialGremium((prevState: any) => ({ ...prevState, anzahlMitglieder }));
    setUpdatedGremium({ ...initialGremium, anzahlMitglieder });
  };

  const onErsatzmitgliederChange = (event: any) => {
    let anzahlErsatzmitglieder: number = parseInt(event.target.value);
    let prevValue = initialGremium.anzahlErsatzmitglieder;

    if (
      anzahlErsatzmitglieder === null ||
      anzahlErsatzmitglieder < 0 ||
      Number.isNaN(anzahlErsatzmitglieder)
    ) {
      setInitialGremium((prevState: any) => ({ ...prevState, prevValue }));
      setUpdatedGremium({ ...initialGremium, anzahlErsatzmitglieder });
      return;
    }

    if (prevValue > anzahlErsatzmitglieder) {
      // if (confirmDecreasingMemberCount() === false) return;
    }

    setInitialGremium((prevState: any) => ({
      ...prevState,
      anzahlErsatzmitglieder,
    }));
    setUpdatedGremium({ ...initialGremium, anzahlErsatzmitglieder });
  };

  const onJAVChange = (event: any) => {
    let anzahlJAV: number = parseInt(event.target.value);
    let prevValue = initialGremium.anzahlJAV;

    if (anzahlJAV === null || anzahlJAV < 0 || Number.isNaN(anzahlJAV)) {
      setInitialGremium((prevState: any) => ({ ...prevState, prevValue }));
      setUpdatedGremium({ ...initialGremium, anzahlJAV });
      return;
    }

    if (prevValue > anzahlJAV) {
      // if (confirmDecreasingMemberCount() === false) return;
    }

    setInitialGremium((prevState: any) => ({ ...prevState, anzahlJAV }));
    setUpdatedGremium({ ...initialGremium, anzahlJAV });
  };

  const onSBVChange = (event: any) => {
    let anzahlSBV: number = parseInt(event.target.value);
    let prevValue = initialGremium.anzahlSBV;

    if (anzahlSBV === null || anzahlSBV < 0 || Number.isNaN(anzahlSBV)) {
      setInitialGremium((prevState: any) => ({ ...prevState, prevValue }));
      setUpdatedGremium({ ...initialGremium, anzahlSBV });
      return;
    }

    if (prevValue > anzahlSBV) {
      // if (confirmDecreasingMemberCount() === false) return;
    }

    setInitialGremium((prevState: any) => ({ ...prevState, anzahlSBV }));
    setUpdatedGremium({ ...initialGremium, anzahlSBV });
  };

  const onGremiumMailChange = (event: any) => {
    let brMail = event.target.value;
    setInitialGremium((prevState: any) => ({ ...prevState, brMail }));
    setUpdatedGremium({ ...initialGremium, brMail });
  };

  const onTeamChange = (_, v) => {
    const teamDisplayName = v.optionValue;
    const defaultTeamId = teamDisplayName
      ? teams.find((t) => t.displayName === teamDisplayName)?.id
      : undefined;

    setInitialGremium((prevState: any) => ({ ...prevState, defaultTeamId }));
    setUpdatedGremium({ ...initialGremium, defaultTeamId });
    setTeamDisplayName(teamDisplayName);

    configDataChange(null);
  };

  const onGremiumChange = (value, parentGremium) => {
    const type = value;
    if (parentGremium?.id) {
      setInitialGremium((prevState: any) => ({
        ...prevState,
        type: type,
        parentGremiumId: parentGremium.id,
      }));
      setUpdatedGremium({
        ...initialGremium,
        type: type,
        parentGremiumId: parentGremium.id,
      });
    }
  };

  const updateSelectedAdministrators = () => {
    const administratorIds = selectedAdministrators;

    if (administratorIds) {
      setAdministratorUserNames(administratorIds);
      setInitialGremium((prevState: any) => ({
        ...prevState,
        administratorUsers: selectedAdministrators,
      }));
    }

    configDataChange(null);
  };

  const updateSelectedAssistants = () => {
    const newSelectedAssistants = updatedSelectedAssistents;

    if (newSelectedAssistants) {
      setSelectedAssistants(newSelectedAssistants);
      setInitialGremium((prevState: any) => ({
        ...prevState,
        assistanceUsers: newSelectedAssistants,
      }));
    }

    configDataChange(null);
  };

  const onGremiumAdministratorChange = async (_, v) => {
    if (!userPermissions) {
      return;
    }
    // This means that it is adding an user
    if (v.selectedOptions.length > selectedAdministrators?.length) {
      setAdminAddDialogOpen(true)
      setSelectedAdmin(v.id)
    }
    else{
      const filteredAdministrators = selectedAdministrators.filter(a => a !== v.id)
      setSelectedAdministrators(filteredAdministrators)
    }
  };

  const onGremiumAssistentChange = async (_, v) => {
    if (!userPermissions) {
      return;
    }
    setSelectedAssistants(v.selectedOptions);
    setUpdatedSelectedAssistents(v.selectedOptions);
  };

  const handleSelectType = (v) => {
    const gremiumTypeFound = gremiumTypes.find(
      (g) => g.label === v!.optionValue
    );
    if (gremiumTypeFound) {
      setSelectedGremiumType(gremiumTypeFound);
      setSelectedGremium(null);
    }
    if (gremiumTypeFound) {
      if (gremiumTypeFound.id !== GremiumTypes.Ausschuss) {
        setInitialGremium((prevState: any) => ({
          ...prevState,
          type: gremiumTypeFound.id,
          parentGremiumId: null,
        }));
        setUpdatedGremium({
          ...initialGremium,
          type: gremiumTypeFound.id,
          parentGremiumId: null,
        });
      } else {
        setInitialGremium((prevState: any) => ({
          ...prevState,
          type: gremiumTypeFound.id,
        }));
        setUpdatedGremium({ ...initialGremium, type: gremiumTypeFound.id });
      }
    }
  };

  const createNewGremium = async () => {
    setIsCreateNew(true);
    setSpreichenClicked(true);

    if (isFormValid() === false) {
      return;
    }
    const resp = await createNewAuschuss(
      { ...updatedGremium, isFirstUse: true },
      handleTokenAccessFailure
    );

    if (resp.data && resp.status === StatusCodes.OK) {
      const newGremium = resp.data;
      localStorage.setItem("selectedAuschuss", newGremium.id);
      auschuss.setGremiumId(newGremium.id);
    }
    setGremiumDialog(false);
  };

  const searchForUsers = async (
    searchTerm: string
  ): Promise<{ users: IGraphUser[]; nextLink: string }> => {
    let users: IGraphUser[] = [];
    const firstPageResponse = await getAllUsersAsyncFirstPage(
      handleTokenAccessFailure,
      searchTerm
    );
    if (firstPageResponse.status === 200) {
      users.push(...firstPageResponse.data.items);
    }
    const mergedUsers = mergeArraysWithoutDuplicatesID(graphUsers, users)
    setGraphUsers(mergedUsers)
    return { users: users, nextLink: firstPageResponse.data.nextLink };
  };

  const searchForUsersWithNextLink = async (
    nextLink: string
  ): Promise<{ users: IGraphUser[]; nextLink: string | null }> => {
    let users: IGraphUser[] = [];
    const nextPageResponse = await getAllUsersAsyncNextLink(
      handleTokenAccessFailure,
      nextLink
    );
    if (nextPageResponse.status === 200) {
      users.push(...nextPageResponse.data.items);
    }
    const mergedUsers = mergeArraysWithoutDuplicatesID(graphUsers, users)
    setGraphUsers(mergedUsers)
    return { users: users, nextLink: nextPageResponse.data.nextLink };
  };

  const loadMoreUsers = async () => {
    if (nextLink === null) return;
    const allMitglieders = await getAllUsersAsyncNextLink(
      handleTokenAccessFailure,
      nextLink
    );
    const mergedUsers = mergeArraysWithoutDuplicatesID(
      graphUsers,
      allMitglieders.data.items
    );
    setGraphUsers(mergedUsers);
    setNextLink(allMitglieders.data.nextLink);
    return {
      assistanceUsers: mergedUsers,
      adminUsers: mergedUsers,
      nextLink: allMitglieders.data.nextLink,
    };
  };

  return (
    <Dialog open={showGremiumDialog}>
      <DialogSurface
        style={{
          width: "60%",
          height: "fit-content",
          overflowY: "hidden",
          minHeight: "350px",
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <span style={{ margin: "auto" }}>
              <Dropdown
                className="gremium-type-dropdown"
                onOptionSelect={(e, v) => handleSelectType(v)}
                value={selectedGremiumType?.label}
                placeholder=""
              >
                {gremiumTypes.map((o, i) => (
                  <Option
                    disabled={
                      o.label === "Neuer Ausschuss" &&
                      !allGremiums.filter(
                        (g) => g.type === GremiumTypes.Betriebsrat
                      )?.length
                    }
                    key={i}
                    value={o.label}
                  >
                    {o.label}
                  </Option>
                ))}
              </Dropdown>
            </span>
            <DismissFilled
              className="dialog-close-icon"
              onClick={() => setGremiumDialog(false)}
              y2=""
            ></DismissFilled>
          </div>
          {selectedGremiumType.label === "Neuer Ausschuss" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 6,
              }}
            >
              <Field
                validationMessage={
                  !selectedGremium && spreichenClicked
                    ? RequiredFieldErrorMessage
                    : ""
                }
                validationState={
                  !selectedGremium && spreichenClicked ? "error" : "none"
                }
              >
                <Dropdown
                  className="auschuss-dropdown"
                  onOptionSelect={(e, v) => {
                    const gremiumFound = allGremiums.find(
                      (g) => g.gremienname === v.optionText
                    );
                    if (gremiumFound) {
                      setSelectedGremium(gremiumFound);
                      onGremiumChange(GremiumTypes.Ausschuss, gremiumFound);
                    }
                  }}
                  value={selectedGremium?.gremienname}
                  placeholder="Wählen Sie ein Gremium aus"
                >
                  {allGremiums
                    .filter(
                      (g) =>
                        g.parentGremiumId === null &&
                        g.type === GremiumTypes.Betriebsrat
                    )
                    .map((o, i) => (
                      <Option key={i} value={o?.id.toString()}>
                        {o.gremienname}
                      </Option>
                    ))}
                </Dropdown>
              </Field>
            </div>
          )}
        </DialogTitle>
        {isLoading ? (
          <Spinner
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "100px",
              height: "50px",
            }}
            className="loader"
            label={LoaderMessage}
          />
        ) : (
          <KonfigurationGremiumForm
            adminAddDialogOpen={adminAddDialogOpen}
            setAdminAddDialogOpen={setAdminAddDialogOpen}
            adminDeleteDialogOpen={adminDeleteDialogOpen}
            adminDropdownRef={adminDropdownRef}
            setAdminDeleteDialogOpen={setAdminDeleteDialogOpen}
            setAddAdmin={setAddAdmin}
            setDeleteAdmin={setDeleteAdmin}
            selectedAdministrators={administratorUserNames}
            selectedAsistents={selectedAssistants}
            configDataChange={configDataChange}
            configDataChanged={configDataChanged}
            currentLoggedUserId={currentLoggedUserId}
            adminUsers={graphUsers}
            assistanceUsers={graphUsers}
            initialGremium={initialGremium}
            isLoading={isLoading}
            teams={teams}
            userHasUpdatePermission={userPermissions}
            teamDisplayName={teamDisplayName}
            isKonfigurationGremium={spreichenClicked}
            onDataUpdate={() => {}}
            onGremiumAssistentChange={onGremiumAssistentChange}
            onErsatzmitgliederChange={onErsatzmitgliederChange}
            onGremiennameChange={onGremiennameChange}
            onGremiumMailChange={onGremiumMailChange}
            onJAVChange={onJAVChange}
            onMitgliederChange={onMitgliederChange}
            onOrtFilialeChange={onOrtFilialeChange}
            onSBVChange={onSBVChange}
            onTeamChange={onTeamChange}
            onFirmenameChange={onFirmenameChange}
            dialogSaveFunction={createNewGremium}
            dialogAbortFunction={() => setGremiumDialog(false)}
            updateAdministratorUserNames={updateSelectedAdministrators}
            updateAssistentUserNames={updateSelectedAssistants}
            onGremiumAdministratorChange={onGremiumAdministratorChange}
            updatedAdministratorUserNames={selectedAdministrators}
            updatedAssitentUserNames={updatedSelectedAssistents}
            isFormValid={isFormValid}
            isCreateNewAuschuss={isCreateNew}
            gremiumNameExists={nameExists}
            isAuschuss={false}
            loadMoreUsers={loadMoreUsers}
            nextLink={nextLink}
            searchForUsers={searchForUsers}
            searchForUsersNextLink={searchForUsersWithNextLink}
          />
        )}
      </DialogSurface>
    </Dialog>
  );
};
