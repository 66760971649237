import { Guid } from "guid-typescript";
import IGremium from "../models/konfiguration/gremium";
import axios from "./axios-decorator";

export const getAuschusse = async (
  handleTokenAccessFailure: (error: any) => void
) => {
  let requestUrl = "api/GremiumInfoDto";
  return await axios.get(requestUrl, handleTokenAccessFailure);
};

export const createNewAuschuss = async (
  gremiumDetails: IGremium,
  handleTokenAccessFailure: (error: any) => void
) => {
  let requestUrl = `/api/GremiumDetailsDto`;
  return await axios.post(requestUrl, handleTokenAccessFailure, gremiumDetails);
};

export const deleteAuschuss = async (
  gremiumId: Guid,
  handleTokenAccessFailure: (error: any) => void
) => {
  let requestUrl = `api/GremiumDetailsDto/${gremiumId}`;
  return await axios.delete(requestUrl, handleTokenAccessFailure);
};
