import React from "react";
import { Route } from "react-router-dom";
import EmptyPage from "../components/registration/empty-page";
import RegistrationForm from "../components/registration/registation-form";
import RegistrationInvalid from "../components/registration/registration-invalid";
import RegistrationUnapproved from "../components/registration/registration-unapproved";
import { RegistrationState } from "../enums/registration-state.enum";
import UnauthorizedPage from "../components/unauthorized-page/unauthorized-page";
import { userHasPermission } from "../Helpers/permissionHelper";
import { getPermissions } from "../api/permission-api";
import { Guid } from "guid-typescript";
import { StatusCodes } from "http-status-codes";
import { getRegistrationState } from "../api/registration-api";
import PermissionsContext from "../components/context/permissions-context";
import { AuschussContextObject } from "../interfaces/providers/asuchuss-provider.interface";
import { Spinner } from "@fluentui/react-components";
import { LoaderMessage } from "../constants/textLabels";

interface IGuardedRouteProps {
  exact: boolean;
  path: string;
  component: any;
  registrationState: RegistrationState;
  // setRegistrationState: Function;
  setPermissions: Function;
  auschuss: AuschussContextObject;
  setPermissionsLoaded: Function;
}

interface IGuardedRouteState {
  component: any;
  isAuschussLoaded: boolean;
  parentAuschussId: string | null;
  userPermissions: any[];
  isLoading: boolean;
}

class GuardedRoute extends React.Component<
  IGuardedRouteProps,
  IGuardedRouteState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      component: EmptyPage,
      isAuschussLoaded: false,
      parentAuschussId: null,
      userPermissions: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    if (!window.location.href.includes("einladungsrueckmeldung")) {
      this.setState({ isLoading: true })
      this.loadRegistrationAndPermissions();
    }
  }

  loadPermissions = async () => {
    this.props.setPermissionsLoaded(false);
    const response = await getPermissions(
      Guid.createEmpty(),
      Guid.createEmpty(),
      this.handleTokenAccessFailure
    );
    if (response.status === StatusCodes.OK && response.data) {
      this.setState(
        { userPermissions: response.data, isLoading: false },
        () => {
          this.props.setPermissions(response.data);
          this.setComponent();
          this.props.setPermissionsLoaded(true);
        }
      );
    }
  };

  loadRegistrationAndPermissions = async () => {
    this.setState({ isLoading: false })
    if (this.props.registrationState === RegistrationState.Approved) {
      this.loadPermissions();
    } else {
      // this.props.setRegistrationState(RegistrationState.Rejected);
    }
  };

  handleAuschussLoad = (value) => {
    this.setState({ isAuschussLoaded: true, parentAuschussId: value });
  };

  handleTokenAccessFailure = (error: string) => {
    alert(error);
  };

  setComponent = () => {
    if (
      !userHasPermission(this.state.userPermissions, "AppAccess") &&
      this.props.registrationState === RegistrationState.Approved
    ) {
      this.setState({ component: UnauthorizedPage });
      return;
    }
    switch (this.props.registrationState) {
      case RegistrationState.None:
        this.setState({ component: RegistrationForm });
        break;
      case RegistrationState.Registered:
        this.setState({ component: RegistrationUnapproved });
        break;
      case RegistrationState.Approved:
        this.setState({ component: this.props.component });
        break;
      case RegistrationState.Rejected:
        this.setState({ component: RegistrationInvalid });
        break;
      case RegistrationState.Deactivated:
        this.setState({ component: RegistrationInvalid });
        break;
      default:
        this.setState({ component: RegistrationInvalid });
    }
  };

  render = () => {
    const context = {
      permissionsArray: this.state.userPermissions,
      registrationState: this.props.registrationState,
      auschuss: this.props.auschuss,
    };

    return (
      <PermissionsContext.Provider value={context}>
        {this.state.isLoading ? <Spinner
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
          }}
          className="loader"
          label={LoaderMessage}
        /> :
          <Route path={this.props.path} exact component={this.state.component} />
        }
      </PermissionsContext.Provider>
    );
  };
}

export default GuardedRoute;
