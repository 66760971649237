import { Guid } from "guid-typescript";
import axios from "./axios-decorator";
import { AxiosHeaders } from "axios";

export const getPermissions = async (
  sitzungId: Guid | string,
  objectId: Guid | string,
  handleTokenAccessFailure: (error: string) => void
) => {
  const requestUrl = `/api/permissions/${sitzungId}/${objectId}`;
  return await axios.get(requestUrl, handleTokenAccessFailure);
};

export const getPermissionsHasAppAccess = async (
  sitzungId: Guid | string,
  objectId: Guid | string,
  handleTokenAccessFailure: (error: string) => void
) => {
  const requestUrl = `/api/permissions/${sitzungId}/${objectId}`;
  return await axios.get(requestUrl, handleTokenAccessFailure, {
    headers: new AxiosHeaders({
      GremiumId: Guid.createEmpty().toString(),
    }),
  });
};
