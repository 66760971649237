import { AxiosHeaders } from "axios";
import IChecklistItem from "../models/nachbereiten/IchecklistItem";
import ICreateChecklistItem from "../models/nachbereiten/create-checklist-item";
import axios from "./axios-decorator";

export const getChecklist = async (sitzungId: string, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemDto/sitzung/${sitzungId}`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const postChecklistItem = async (checklistItem: ICreateChecklistItem, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemDto`;
    return await axios.post(url, handleTokenAccessFailure, checklistItem);
}

export const putChecklistItem = async (checklistItem: IChecklistItem, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemDto/${checklistItem.id}`;
    return await axios.put(url, handleTokenAccessFailure, checklistItem);
}

export const patchChecklistItem = async (propName: string, propValue: boolean, id: string, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemDto/${id}`;
    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    }
    return await axios.patch(url, handleTokenAccessFailure, data, config);
}

export const deleteChecklistItem = async (id: string, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemDto/${id}`;
    return await axios.delete(url, handleTokenAccessFailure);
}