export enum Einladungstyp {
    Mitglied = 1,
    Ersatzmitglied = 2,
    JAV = 3,
    SBV = 4,
    Arbeitgeber = 5,
    Gäste = 6,
    Sonstige = 7,
    MitgliedOnline = 8,
    MitgliedHybrid = 9,
}