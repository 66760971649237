import axios from "./axios-decorator";
import ITagesordnungspunkt from "../models/tagesordnung/tagesordnungspunkt";
import ITagesordnung from "../models/tagesordnung/tagesordnung";
import { AxiosHeaders } from "axios";

export const getAgenda = async (meetingId: string, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/TagesordnungDto/sitzung/' + meetingId;
    return await axios.get(url, handleTokenAccessFailure);
}

export const getProtokoll = async (id: string, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ProtokollDto/${id}`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const getSitzungProtokoll = async (id: string, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ProtokollDto/sitzung/${id}`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const postAgenda = async (agenda: ITagesordnung, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/TagesordnungDto';
    return await axios.post(url, handleTokenAccessFailure, agenda);
}
export const getTagesordnung = async (handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/TagesordnungDto';
    return await axios.get(url, handleTokenAccessFailure);
}

export const patchCheckbox = async (id: string, propName, propValue, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = `/api/GremiumDetailsDto/${id}`;
    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    };
    return await axios.patch(url, handleTokenAccessFailure, data, config);
}

export const patchAgenda = async (agendaId: string, propName: string, propValue, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/TagesordnungDto/' + agendaId;
    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    };
    return await axios.patch(url, handleTokenAccessFailure, data, config);
}

export const getAgendaItems = async (meetingId: string, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/TagesordnungpunktDto/sitzung/' + meetingId;
    return await axios.get(url, handleTokenAccessFailure);
}

export const postAgendaItem = async (agendaItem: ITagesordnungspunkt, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/TagesordnungpunktDto';
    return await axios.post(url, handleTokenAccessFailure, agendaItem);
}

export const putAgendaItem = async (agendaItem: ITagesordnungspunkt, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/TagesordnungpunktDto/' + agendaItem.id;
    return await axios.put(url, handleTokenAccessFailure, agendaItem);
}

export const deleteAgendaItem = async (agendaItemId: string, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/TagesordnungpunktDto/' + agendaItemId;
    return await axios.delete(url, handleTokenAccessFailure);
}

export const putAgendaItems = async (meetingId: string, agendaItems: ITagesordnungspunkt[], handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/TagesordnungpunktDto/sitzung/' + meetingId;
    return await axios.put(url, handleTokenAccessFailure, agendaItems)
}

export const patchAgendaItem = async (agendaItemId: string, propName: string, propValue, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/TagesordnungpunktDto/' + agendaItemId;
    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    };
    return await axios.patch(url, handleTokenAccessFailure, data, config);
} 