import * as React from "react";
import { Text } from "@fluentui/react-components";
import { AddEventIcon } from "../../utils/icons";

class RegistrationInvalid extends React.Component {

    render = () => {
        return (
            <div className="container-div">
                <div className="container-subdiv">
                    <div className="error-message">
                        <div className='dropdownMenuTrigger' >
                            <AddEventIcon />
                        </div>
                        <Text weight='semibold'>Sie haben leider keine Berechtigung, um hier zuzugreifen.</Text>
                    </div>
                </div>
            </div>
        );
    }
};

export default RegistrationInvalid;