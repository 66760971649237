import { Combobox, Field, Option } from "@fluentui/react-components";
import { Component } from "react";
import IGraphUser from "../../../models/graph-user";
import {
  getAllUsersAsyncFirstPage,
  getAllUsersAsyncNextLink,
} from "../../../api/users";
import {
  addItemWithoutDuplicatesID,
  mergeArraysWithoutDuplicatesID,
} from "../../../utils/utility";

class CustomCombobox extends Component<
  {
    selectedValue: string;
    onSelectFunction: any;
    options: JSX.Element[];
    userHasUpdatePermission: boolean;
    updateGraphUsers: Function;
    allUsers: IGraphUser[];
    userAlreadyPickedInWorkerCouncil: Function;
    userAlreadyPickedInSBV: Function;
    type: number;
  },
  {
    comboValue: string;
    searchString: string;
    searchUsers: IGraphUser[];
    searchNextLink: string;
    isSearching: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      comboValue: this.props.selectedValue,
      searchString: "",
      searchUsers: [],
      searchNextLink: "",
      isSearching: false,
    };
  }

  // clear value on focus
  onFocus = () => {
    this.setState({ comboValue: "" });
  };

  // update value to selected options on blur
  onBlur = () => {
    this.setState({
      comboValue: this.props.selectedValue,
      searchString: "",
      searchUsers: [],
      searchNextLink: "",
      isSearching: false,
    });
  };

  searchForUsers = async (
    searchTerm: string
  ): Promise<{ users: IGraphUser[]; nextLink: string }> => {
    let users: IGraphUser[] = [];
    this.setState({ isSearching: true });
    const firstPageResponse = await getAllUsersAsyncFirstPage(() => {},
    searchTerm);
    if (firstPageResponse.status === 200) {
      users.push(...firstPageResponse.data.items);
    }
    this.setState({ isSearching: false });
    return { users: users, nextLink: firstPageResponse.data.nextLink };
  };

  searchForUsersWithNextLink = async (): Promise<{
    users: IGraphUser[];
    nextLink: string | null;
  }> => {
    if (!this.state.searchNextLink) return { users: [], nextLink: null };
    let users: IGraphUser[] = [];
    this.setState({ isSearching: true });
    const nextPageResponse = await getAllUsersAsyncNextLink(() => {},
    this.state.searchNextLink);
    if (nextPageResponse.status === 200) {
      users.push(...nextPageResponse.data.items);
    }
    this.setState({
      searchNextLink: nextPageResponse.data.nextLink,
      isSearching: false,
    });
    if (
      mergeArraysWithoutDuplicatesID(this.state.searchUsers, users).length ===
      this.state.searchUsers.length
    ) {
      await this.searchForUsersWithNextLink();
    }
    this.setState((prevState) => ({
      searchUsers: mergeArraysWithoutDuplicatesID(prevState.searchUsers, users),
    }));
    return { users: users, nextLink: nextPageResponse.data.nextLink };
  };

  searchIntersectionObserver = new IntersectionObserver(
    async (entries) => {
      const entry = entries[0];
      if (entry.intersectionRatio <= 0) return;
      if (this.state.isSearching) return;
      await this.searchForUsersWithNextLink();
    },
    { root: document.querySelector(".dropdown-menu") }
  );

  searchItemRef = (e) => {
    if (e) {
      this.searchIntersectionObserver.disconnect();
      this.searchIntersectionObserver.observe(e);
    }
  };

  generateSearchOptions = (searchOptions) => {
    const filteredSearchOptions = searchOptions.filter(u => this.props.type === 4 ? !this.props.userAlreadyPickedInSBV(u) : !this.props.userAlreadyPickedInWorkerCouncil(u))
    return filteredSearchOptions.map((user, index) => {
      return (
        <>
          <Option key={index} value={user.displayName}>{`${user.displayName} ${
            !user.isActive ? "(Inaktiv)" : ""
          }`}</Option>
          {this.state.searchString !== "" &&
            this.state.searchNextLink &&
            index === searchOptions.length - 1 && (
              <Option
                ref={this.searchItemRef}
                className={"searchForMoreUsers"}
                aria-posinset={index}
                aria-setsize={searchOptions.length}
                checkIcon={null}
                disabled
                value={"Searching for users..."}
                key={`searching-${index}`}
              >
                Searching for more users...
              </Option>
            )}
        </>
      );
    });
  };

  render() {
    const {
      selectedValue,
      onSelectFunction,
      options,
      userHasUpdatePermission,
    } = this.props;

    return (
      <Field
        style={{ width: "100%" }}
        validationState={selectedValue === "" ? "error" : "none"}
      >
        <Combobox
          multiselect={true}
          value={this.state.comboValue}
          selectedOptions={[selectedValue]}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onOptionSelect={async (e, data) => {
            const allUsersForSelect = mergeArraysWithoutDuplicatesID(
              this.state.searchUsers,
              this.props.allUsers
            );
            const selectedUser = allUsersForSelect.find(
              (user) => user.displayName === data.optionValue
            );
            if (selectedUser) {
              await this.props.updateGraphUsers(
                addItemWithoutDuplicatesID(selectedUser, this.props.allUsers)
              );
              onSelectFunction(data);
              this.setState({ comboValue: data.optionValue || "" });
            }
          }}
          freeform
          onChange={async (e) => {
            this.setState(
              {
                comboValue: e.target.value,
                searchString: e.target.value,
                //@ts-ignore
                searchUsers: [],
              },
              async () => {
                const searchUsers = await this.searchForUsers(e.target.value);
                this.setState((prevState) => ({
                  searchUsers: mergeArraysWithoutDuplicatesID(
                    prevState.searchUsers,
                    searchUsers.users
                  ),
                  searchNextLink: searchUsers.nextLink,
                }));
              }
            );
          }}
          placeholder="Suche nach Person"
          listbox={{
            style: {
              height: "auto",
              width: "500px",
              maxHeight: "150px",
              overflow: "auto",
            },
            className: "dropdown-menu",
          }}
          style={{
            width: "100%",
          }}
          disabled={!userHasUpdatePermission}
        >
          {this.state.searchString
            ? this.generateSearchOptions(this.state.searchUsers)
            : options}
        </Combobox>
      </Field>
    );
  }
}

export default CustomCombobox;
