//sort by type ascending and then by sequenz ascending
export const sortMitglieder = (boardMembers) => {
  if (boardMembers) {
    boardMembers.sort((user1, user2) => {
      if (user1.userType > user2.userType) return 1;
      if (user1.userType < user2.userType) return -1;
      if (user1.sequenz > user2.sequenz) return 1;
      if (user1.sequenz < user2.sequenz) return -1;
      return 0;
    });
  } 
  return boardMembers;
};
