import { StatusCodes } from "http-status-codes";
import { getAllMitglieders } from "../api/konfig-mitglieder-api";
import IMitgliederGremium from "../models/konfiguration/gremium-mitglieder";
import { getGremiumKonfig } from "../api/konfiguration-api";
import { Guid } from "guid-typescript";

const handleTokenAccessFailure = (error: string) => {
    alert(error);
}

const mitgliederTypeFullyInserted = (requiredCount: number, type: number, mitglieder: IMitgliederGremium[]) => {
    let count = 0;
    mitglieder.forEach(member => {
        if (member.userType === type) { count++; }
    });
    return count === requiredCount;
}

export const mitgliederFullyInserted = async () => {
    const selectedGremium = localStorage.getItem("selectedAuschuss")!
    const resp = await getGremiumKonfig(selectedGremium, handleTokenAccessFailure)
    let response = await getAllMitglieders(handleTokenAccessFailure);
    if (response.data && response.status === StatusCodes.OK && resp.data) {
        if(response.data?.length === 0) return false
        const filterMitglieders: IMitgliederGremium[] = response.data.filter((m) => (m.gremiumId === selectedGremium) && (m.graphUserId != Guid.createEmpty().toString()))
        if (response.status === StatusCodes.OK && response.data) {
            return mitgliederTypeFullyInserted(resp.data.anzahlMitglieder, 1, filterMitglieders)
                && mitgliederTypeFullyInserted(resp.data.anzahlErsatzmitglieder, 2, filterMitglieders)
                && mitgliederTypeFullyInserted(resp.data.anzahlJAV, 3, filterMitglieders)
                && mitgliederTypeFullyInserted(resp.data.anzahlSBV, 4, filterMitglieders);
        }
    }
    return false;
}




