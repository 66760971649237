import * as React from "react";
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle } from "@fluentui/react-components";
import './dialog.scss';

interface IConfirmDialogProps {

}

interface IConfirmDialogState {
    isDialogHidden: boolean;
    dialogMessage: string;
    confirmButtonRef;
    cancelButtonRef;
    userResponded: boolean,
    userResponse: boolean,
    functionToExecute?: Function;
}

export default class ConfirmDialog extends React.Component<IConfirmDialogProps, IConfirmDialogState>{

    constructor(props: any) {
        super(props);
        this.state = {
            isDialogHidden: true,
            dialogMessage: "",
            confirmButtonRef: React.createRef<HTMLButtonElement>(),
            cancelButtonRef: React.createRef<HTMLButtonElement>(),
            userResponded: false,
            userResponse: false,
        }
    }

    componentDidUpdate = () => {
        if (this.state.userResponded) {
            if (this.state.userResponse && this.state.functionToExecute != undefined) {
                this.state.functionToExecute();
            }
            this.setState({
                userResponded: false,
                functionToExecute: undefined,
            });
        }
    }

    showConfirmDialog = (message: string, functionToExecute: Function) => {
        this.setState({
            dialogMessage: message,
            isDialogHidden: false,
            functionToExecute: functionToExecute,
        });
    }

    onConfirmClick = () => {
        this.setState({
            isDialogHidden: true,
            userResponded: true,
            userResponse: true,
        });
    }

    onCancelClick = () => {
        this.setState({
            isDialogHidden: true,
            userResponded: true,
            userResponse: false,
        });
    }

    render() {
        return (
            <Dialog
                open={!this.state.isDialogHidden}
            >
                <DialogSurface>
                    <DialogTitle style={{maxHeight: 450, overflowY:'scroll' }}>{this.state.dialogMessage}</DialogTitle>
                    <DialogBody>
                        <DialogContent style={{ display: "flex", justifyContent: "end", gap: 5,maxHeight: 450, overflowY:'scroll' }}>
                            <Button onClick={this.onCancelClick}>Abbrechen</Button>
                            <Button appearance="primary" onClick={this.onConfirmClick}>Fortfahren</Button>
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    }
}