import { AxiosHeaders } from "axios";
import IAddressaten from "../models/einladung/addressaten";
import IEinladung from "../models/einladung/einladung";
import axios from "./axios-decorator";

export const getInvitation = async (meetingId: string, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungDto/sitzung/' + meetingId;
    return await axios.get(url, handleTokenAccessFailure);
}

export const getInvitationById = async (id: string, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungDto/' + id;
    return await axios.get(url, handleTokenAccessFailure);
}

export const postInvitation = async (invitation: IEinladung, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungDto';
    return await axios.post(url, handleTokenAccessFailure, invitation);
}

export const putInvitation = async (invitation: IEinladung, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungDto/' + invitation.id;
    return await axios.put(url, handleTokenAccessFailure, invitation);
}

export const patchInvitation = async (invitationId: string, propName: string, propValue, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungDto/' + invitationId;
    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    };
    return await axios.patch(url, handleTokenAccessFailure, data, config);
}

export const getAllAddresses = async (handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungsadressatDto';
    return await axios.get(url, handleTokenAccessFailure);
}

export const getAddresses = async (meetingId: string, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungsadressatDto/sitzung/' + meetingId;
    return await axios.get(url, handleTokenAccessFailure);
}

export const postAddress = async (address: IAddressaten, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungsadressatDto'
    return await axios.post(url, handleTokenAccessFailure, address);
}

export const putAddress = async (id: string,address: IAddressaten, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungsadressatDto/' + id;
    return await axios.put(url, handleTokenAccessFailure, address);
}

export const patchAddress = async (id: string, propName: string, propValue, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungsadressatDto/' + id;
    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    };
    return await axios.patch(url, handleTokenAccessFailure, data, config);
}

export const deleteAddress = async (addressId: string, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/EinladungsadressatDto/' + addressId;
    return await axios.delete(url, handleTokenAccessFailure);
}
