import * as React from "react";
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from "react-router-dom";
import Constants, { Themes } from "../../constants/constants";
import { IWithContext } from "../../providers/context-provider";
import "./registration.scss";
import { Field, Button, Checkbox, Input } from "@fluentui/react-components";

interface IRegistrationState {
    isLoading: boolean;
    isMobileView: boolean;
}

interface IRegistrationProps extends WithTranslation, IWithContext, RouteComponentProps {
}

class Registration extends React.Component<IRegistrationProps, IRegistrationState> {
    theme: string = Themes.default;

    /** 
     * Constructor which initializes state. 
     */
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            isMobileView: window.outerWidth <= Constants.maxWidthForMobileView,
        };
    }

    /**
    * Renders the component
    */
    render = () => {
        return (
            <div className="page-content">
                <div style={{ width: "100%", justifyContent: "normal" }}>
                    <Field label="Firmenname" id="firma-name" required>
                        <Input placeholder="Name der Firma" />
                    </Field>
                    <Field label="Gremiumname" id="gremium-name" required>
                        <Input placeholder="Name des Gremiums" />
                    </Field>
                    <Field label="Vorname" id="vorname" required >
                        <Input placeholder="Vorname des Ansprechpartners" />
                    </Field>
                    <Field label="Nachname" id="nachname" required>
                        <Input placeholder="Nachname des Ansprechpartners" />
                    </Field>
                    <Field label="E-Mail" id="mail" required>
                        <Input placeholder="E-Mail-Adresse des Ansprechpartners" />
                    </Field>
                    <Field label="Ich stimme den AGB zu" id="tou" required>
                        <Checkbox />
                    </Field>
                    <Field label="Ich stimme den Datenschutzbestimmungen zu" id="dpa" required>
                        <Checkbox />
                    </Field>
                    <Field
                    >
                        <Button appearance="primary">
                            Registrieren
                        </Button>
                    </Field>
                </div>
            </div>
        );
    }
}

export default Registration;