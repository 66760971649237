import { Component, createContext } from "react";
import axiosJWTDecoratorInstance from "../api/axios-decorator";
import { getAuschusse } from "../api/gremium-details-api";
import {
  IAuschussState,
  IAuschussProps,
} from "../interfaces/providers/asuchuss-provider.interface";

const AuschussContext = createContext({});

class AuschussProvider extends Component<IAuschussProps, IAuschussState> {
  constructor(props: any) {
    super(props);
    this.state = {
      gremiumId: null,
      gremiumList: [],
    };
  }

  handleGremiumInterceptor = (value: string) => {
    axiosJWTDecoratorInstance.removeRequestInterceptor();
    axiosJWTDecoratorInstance.setGremiumInterceptor(value);
  };

  setGremiumId = async (value) => {
    this.props.handleAuschussLoad(false);
    this.setState({ gremiumId: value }, () => {
      this.handleGremiumInterceptor(value);
      this.props.handleAuschussLoad(true);
    });
  };

  handleTokenAccessFailure = (error: string) => {
    console.error(error);
  };

  fetchAllAuschusse = async (
    newId: string | null = null,
    refresh: boolean = false,
    afterDelete: boolean = false,
    deletingId: string | null = null
  ) => {
    let parentGremiumID = ""
    let gremiumList = this.state.gremiumList
    if(deletingId){
      parentGremiumID = gremiumList.find(g => g.id === deletingId)?.parentGremiumId || ""
      const filteredGremiumList = this.state.gremiumList.filter(g => g.id !== deletingId)
      gremiumList = filteredGremiumList
      this.setState({gremiumList: filteredGremiumList})
    }
    const defaultGremium = parentGremiumID ? gremiumList.find(g => g.id === parentGremiumID) : gremiumList.find(
      (g) => g.parentGremiumId === null
    )

    if (afterDelete) {
      this.handleGremiumInterceptor(defaultGremium?.id!);
      const response = await getAuschusse(this.handleTokenAccessFailure)
      this.setState({gremiumList: response.data})
      localStorage.setItem("selectedAuschuss", defaultGremium!.id)
      return
    }

    if (refresh) {
      this.props.handleAuschussLoad(false);
    }

    getAuschusse(this.handleTokenAccessFailure).then((resp) => {
      if (resp.status !== 200) return;
      const parent = resp.data.find(
        (gremium) => gremium.parentGremiumId === null
      );
      this.setState(
        { gremiumId: newId || parent!.id, gremiumList: resp.data },
        async () => {
          const localStorageValue = localStorage.getItem("selectedAuschuss");
          const found = this.state.gremiumList.find(
            (g) => g.id === localStorageValue
          );
          if (!found) {
            const response = await getAuschusse(this.handleTokenAccessFailure)
            const filterGremium = response.data.filter((g) => g.parentGremiumId === null)
            localStorage.setItem("selectedAuschuss", filterGremium[0].id)
            this.handleGremiumInterceptor(filterGremium[0].id || newId || parent!.id);
          } else {
            this.handleGremiumInterceptor(found?.id || newId || parent!.id);
          }
          if (refresh) {
            this.props.handleAuschussLoad(true);
          }
        }
      );
    });
  };

  componentDidMount(): void {
    const localStorageValue = localStorage.getItem("selectedAuschuss");
    this.fetchAllAuschusse(localStorageValue || null, true, false);
  }

  componentDidUpdate(
    prevProps: Readonly<IAuschussProps>,
    prevState: Readonly<IAuschussState>,
    snapshot?: any
  ): void {
    if (
      prevState.gremiumId &&
      this.state.gremiumId &&
      prevState.gremiumId !== this.state.gremiumId
    ) {
      this.fetchAllAuschusse(this.state.gremiumId, true, false);
    }
  }

  render() {
    const { children } = this.props;
    const { gremiumId, gremiumList } = this.state;
    const { setGremiumId, fetchAllAuschusse } = this;

    return (
      <AuschussContext.Provider
        value={{
          gremiumId: gremiumId,
          gremium: gremiumList.find((l) => l.id === gremiumId),
          setGremiumId: setGremiumId,
          gremiumList: gremiumList,
          refetchAuschusse: fetchAllAuschusse,
        }}
      >
        {children}
      </AuschussContext.Provider>
    );
  }
}

export { AuschussContext, AuschussProvider };
