import { Dialog, DialogBody, DialogContent, DialogSurface, Spinner, Card, Tooltip } from "@fluentui/react-components";
import { Guid } from "guid-typescript";
import { StatusCodes } from "http-status-codes";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { getHome } from "../api/home-api";
import { Sitzungsart } from "../enums/sitzungsart.enum";
import { Sitzungsformat } from "../enums/sitzungsformat.enum";
import { SitzungsStatus } from "../enums/sitzungsstatus.enum";
import IHomeSitzung from "../models/home/homeSitzung";
import { IWithContext } from "../providers/context-provider";
import "./main.scss";
import IPermission from "../models/IPermission";
import { getPermissions, getPermissionsHasAppAccess } from "../api/permission-api";
import PermissionsContext from "./context/permissions-context";
import { userHasPermission } from "../Helpers/permissionHelper";
import { mitgliederFullyInserted } from "../Helpers/mitgliederMembersHelper";
import { KanalLimitMessage, NeueSitzungDisabledGremiumMembersMissingInConfig, NeueSitzungDisabledNoPermissions } from "../constants/textLabels";
import MeetingForm from './sitzung/sitzung-form/meeting-form';
import { getAllMeetings } from "../api/meeting-dto-api";
import { getAllGremiumKonfigs, patchGremiumKonfig } from "../api/konfiguration-api";
import UnauthorizedPage from "./unauthorized-page/unauthorized-page";
import moment from "moment";
import { getInvitation } from "../api/invitations-dto-api";
import { getChecklist } from "../api/checklist-dto-api";
import { CheckIconLarge, LastSitzungIconLarge, NextIcon, NextSitzungIconLarge } from "../utils/icons";
import { getGremiumDetailDto } from "../api/konfig-tagesordnungspunktvorlage-api";
import { getChannelsCount } from "../api/sitzungen-api";
import AlertDialog from "./common/dialog/alert-dialog";

interface IHomeState {
  istErsteNutzung: boolean;
  nachsteSitzung: IHomeSitzung | null;
  letzteSitzung: IHomeSitzung | null;
  sitzungFormOpen: boolean;
  sitzungId: string;
  rows: any[];
  nachteSitzungPermissions: IPermission[];
  letzteSitzungPermissions: IPermission[];
  sitzungFormPermissions: IPermission[];
  councilMembersInserted;
  isLoading: boolean;
  nachsteSitzungStatus: SitzungsStatus;
  letzteSitzungStatus: SitzungsStatus;
  showTeamAlertCount: boolean
}

interface IHomeProps extends IWithContext, RouteComponentProps { }

class MainPage extends React.Component<IHomeProps, IHomeState> {
  static contextType = PermissionsContext;
  context!: React.ContextType<typeof PermissionsContext>

  constructor(props: any) {
    super(props);
    this.state = {
      showTeamAlertCount: false,
      isLoading: false,
      istErsteNutzung: true,
      nachsteSitzung: null,
      letzteSitzung: null,
      nachsteSitzungStatus: SitzungsStatus.Default,
      letzteSitzungStatus: SitzungsStatus.Default,
      sitzungFormOpen: false,
      sitzungId: Guid.EMPTY,
      rows: [],
      nachteSitzungPermissions: [],
      letzteSitzungPermissions: [],
      sitzungFormPermissions: [],
      councilMembersInserted: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    await this.getConfig();
    await this.loadSitzungenAsync();

    window.addEventListener("resize", this.onScreenSizeChange);
    if (!localStorage.getItem("selectedAuschuss")) {
      const responsePermissions = await getPermissionsHasAppAccess(
        Guid.createEmpty(),
        Guid.createEmpty(),
        this.handleTokenAccessFailure
      )
      if (responsePermissions.data[10].value) {
        const response = await getAllGremiumKonfigs(this.handleTokenAccessFailure)
        const filterGremium = response.data.filter((g) => g.parentGremiumId === null)
        localStorage.setItem("selectedAuschuss", filterGremium[0].id)
      }
    }
    const councilMembersInserted = await mitgliederFullyInserted();
    this.setState({
      councilMembersInserted: councilMembersInserted,
    });
    if (this.state.nachsteSitzung) {
      const nachste = this.state.nachsteSitzung.start
      this.state.nachsteSitzung.start = this.getGermanTime(nachste);
      this.getSitzungStatus(this.state.nachsteSitzung.id, 'nachste', this.state.nachsteSitzung)
    }
    if (this.state.letzteSitzung) {
      const letzte = this.state.letzteSitzung.start
      this.state.letzteSitzung.start = this.getGermanTime(letzte);
      this.getSitzungStatus(this.state.letzteSitzung.id, 'letzte', this.state.letzteSitzung)
    }
  }

  getSitzungStatus = async (id, type, sitzung) => {
    const resp = await getInvitation(id, this.handleTokenAccessFailure)

    let sitzungStatus
    if (resp.data.length !== 0) {
      const status = resp.data[0].einladungsstatus
      if (status < 2 || !status) {
        sitzungStatus = SitzungsStatus.Sitzungsvorbereitung
      } else if (status >= 2 && sitzung.end > new Date().toISOString()) {
        sitzungStatus = SitzungsStatus.Kurz
      } else if (status >= 2 && sitzung.end < new Date().toISOString()) {
        const checklistsResponse = await getChecklist(id, this.handleTokenAccessFailure)
        const allChecked = checklistsResponse.data.filter((c) => c.value === false)
        if (allChecked.length === 0) {
          sitzungStatus = SitzungsStatus.Ende
        } else {
          sitzungStatus = SitzungsStatus.Sitzungsnachbereitung
        }
      }
    } else {
      sitzungStatus = SitzungsStatus.Sitzungsvorbereitung
    }

    if (type === 'nachste') {
      this.setState({
        nachsteSitzungStatus: sitzungStatus
      })
    }
    if (type === 'letzte') {
      this.setState({
        letzteSitzungStatus: sitzungStatus
      })
    }
  }

  getGermanTime = (date) => {
    const offsetMillis = 1 * 60 * 60 * 1000;
    let start = new Date(date);
    const utcStart = start.getTime();
    const utcPlusOneTimestamp = utcStart + offsetMillis;
    const startDate = new Date(utcPlusOneTimestamp);
    return startDate
  }

  componentDidUpdate(): void { }

  onScreenSizeChange = () => { };

  handleTokenAccessFailure = (error: string) => {
    alert(error);
    this.props.history.push("/signin");
  };

  loadMeetingSpecificPermissions = async () => {
    //Nachte Sitzung permissions
    if (this.state.nachsteSitzung) {
      const responseNachte = await getPermissions(
        this.state.nachsteSitzung.id,
        Guid.createEmpty(),
        this.handleTokenAccessFailure
      );
      if (responseNachte.status === StatusCodes.OK && responseNachte.data) {
        this.setState({ nachteSitzungPermissions: responseNachte.data });
      }
    }
    //Letzte Sitzung permissions
    if (this.state.letzteSitzung) {
      const responseLetzte = await getPermissions(
        this.state.letzteSitzung.id,
        Guid.createEmpty(),
        this.handleTokenAccessFailure
      );
      if (responseLetzte.status === StatusCodes.OK && responseLetzte.data) {
        this.setState({ letzteSitzungPermissions: responseLetzte.data });
      }
    }
  };

  getConfig = async () => {
    const response = await getHome(this.handleTokenAccessFailure)
    this.setState({ istErsteNutzung: response.data.istErsteNutzung })

    if (response.data.nächsteSitzung || response.data.letzteSitzung) {
      this.setState({ istErsteNutzung: false })
    } else {
      if (response.data.istErsteNutzung) {
        const konfigurationValid = await mitgliederFullyInserted();
        if (konfigurationValid) {
          patchGremiumKonfig(localStorage.getItem("selectedAuschuss")!, 'isFirstUse', false, this.handleTokenAccessFailure)
          this.setState({ istErsteNutzung: false })
        }
      }
    }
    if (response.status === StatusCodes.OK && response.data) {
      //this.setState(response.data);
      this.setState({ nachsteSitzung: response.data.nächsteSitzung, letzteSitzung: response.data.letzteSitzung })
    }
  }

  loadSitzungenAsync = async () => {
    let response = await getAllMeetings();
    if (response.status === StatusCodes.OK && response.data.length > 0) {
      response.data = response.data.map((item) => ({
        ...item,
        start: item.start,
        sitzungsartstring:
          item.sitzungsart === Sitzungsart.Ordentlich
            ? "Ordentliche Sitzung"
            : "Außerordentliche Sitzung",
        sitzungsformatstring:
          item.sitzungsformat === Sitzungsformat.Präsenz
            ? "Präsenz"
            : item.sitzungsformat === Sitzungsformat.Online
              ? "Online"
              : "Hybrid",
      }));
      this.setState(
        { rows: response.data, isLoading: false },
        this.loadMeetingSpecificPermissions
      );
    } else {
      this.setState({ isLoading: false })
    }
  };

  handleOnClickNewSitzung = async () => {
    const response = await getGremiumDetailDto(localStorage.getItem('selectedAuschuss')!, this.handleTokenAccessFailure)
    const channelsCount = await getChannelsCount(response.data.defaultTeamId, this.handleTokenAccessFailure)
    if (channelsCount.data >= 950) {
      this.setState({ showTeamAlertCount: true })
    }
    this.setState({
      sitzungFormOpen: true,
      sitzungId: Guid.EMPTY,
      sitzungFormPermissions: this.context.permissionsArray,
    });
  };

  openNachteSitzungModal = () => {
    this.setState({
      sitzungFormOpen: true,
      sitzungId: this.state.nachsteSitzung!.id.toString(),
      sitzungFormPermissions: this.state.nachteSitzungPermissions,
    });
  };

  openLetzteSitzungModal = () => {
    this.setState({
      sitzungFormOpen: true,
      sitzungId: this.state.letzteSitzung!.id.toString(),
      sitzungFormPermissions: this.state.letzteSitzungPermissions,
    });
  };

  closeSitzungModal = async () => {
    await this.loadSitzungenAsync();
    await this.setState({ sitzungFormOpen: false });
  };

  getSitzungsStatusString = (status): string => {
    switch (status) {
      case SitzungsStatus.Sitzungsvorbereitung:
        return "Sitzungsvorbereitung";
      case SitzungsStatus.Kurz:
        return "Kurz vor der Sitzung";
      case SitzungsStatus.Sitzungsnachbereitung:
        return "Sitzungsnachbereitung";
      case SitzungsStatus.Ende:
        return "Sitzung abgeschlossen";
      default:
        return "";
    }
  };

  render = () => {
    return (
      <div className="container-div margins-sitzung">
        <AlertDialog
          isDialogHidden={!this.state.showTeamAlertCount}
          message={KanalLimitMessage}
          closeFunction={() => { this.setState({ showTeamAlertCount: false }) }} />
        {this.state.sitzungFormOpen && !this.state.showTeamAlertCount &&
          <div className="dialog-background">
            <Dialog
              modalType="non-modal"
              open={this.state.sitzungFormOpen}>
              <DialogSurface style={{ minWidth: "90%", height: "90vh", overflow: "scroll" }}>
                <DialogBody>
                  <DialogContent>
                    <MeetingForm
                      gremiumId={this.context?.auschuss?.gremiumId!}
                      closeDialog={this.closeSitzungModal}
                      meetingId={this.state.sitzungId}
                      allSitzung={this.state.rows}
                      userPermissions={this.state.sitzungFormPermissions}
                    />
                  </DialogContent>
                </DialogBody>
              </DialogSurface>
            </Dialog>
          </div>
        }
        {userHasPermission(this.context.permissionsArray, 'GremiumRead') && <h1>Betriebsrat360 | Ihr digitaler 360° Assistent bei der BR-Arbeit</h1>}
        {this.state.isLoading ? <Spinner style={{
          width: "100%",
          height: "100vh",
          position: "fixed",
          top: "0",
          left: "0",
        }} /> :
          userHasPermission(this.context.permissionsArray, 'GremiumRead') ? <>
            <div className="grid-style">
              {!this.state.istErsteNutzung &&
                <>
                  {userHasPermission(this.context.permissionsArray, 'SitzungCreate') && this.state.councilMembersInserted ?
                    <Card onClick={!userHasPermission(this.context.permissionsArray, "SitzungCreate") || !this.state.councilMembersInserted ? () => { } : this.handleOnClickNewSitzung}
                      className={"card-style"}
                      selected={false}
                    >
                      <div className="card-icon-big">
                        <CheckIconLarge />
                      </div>
                      {/* <Icon className='card-icon-big' iconName="Accept" /> */}
                      <h2>Neue Sitzung</h2>
                      Klicken Sie, um eine neue Sitzung zu erstellen
                    </Card>
                    :
                    <Tooltip
                      relationship="label"
                      content={!userHasPermission(this.context.permissionsArray, "SitzungCreate") ? NeueSitzungDisabledNoPermissions : !this.state.councilMembersInserted ? NeueSitzungDisabledGremiumMembersMissingInConfig : ''}
                    ><Card onClick={!userHasPermission(this.context.permissionsArray, "SitzungCreate") || !this.state.councilMembersInserted ? () => { } : this.handleOnClickNewSitzung}
                      className={"card-style"}
                      selected={false}
                    >
                        <div className="card-icon-big">
                          <CheckIconLarge />
                        </div>
                        <h2>Neue Sitzung</h2>
                        Klicken Sie, um eine neue Sitzung zu erstellen
                      </Card></Tooltip>
                  }
                  <Card className={this.state.nachsteSitzung == null || !userHasPermission(this.state.nachteSitzungPermissions, "SitzungRead") ? "card-style-disabled" : "card-style"} onClick={this.openNachteSitzungModal}
                  >
                    <div className="card-icon-big">
                      <NextSitzungIconLarge />
                    </div>

                    <h2>Nächste Sitzung</h2>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {this.state.nachsteSitzung ?
                        <> <span><strong>Name:</strong> {this.state.nachsteSitzung?.name || ""}</span>
                          <span><strong>Datum:</strong> {this.state.nachsteSitzung ? moment(this.state.nachsteSitzung?.start).format("DD.MM.YYYY - HH:mm") : ''}</span>
                          <span><strong>Status:</strong> {this.getSitzungsStatusString(this.state.nachsteSitzungStatus)}</span></>
                        : "Aktuell ist keine Sitzung geplant"}
                    </div>

                  </Card>
                  <Card className={this.state.letzteSitzung == null || !userHasPermission(this.state.letzteSitzungPermissions, "SitzungRead") ? "card-style-disabled" : "card-style"} onClick={this.openLetzteSitzungModal}
                  >
                    <div className="card-icon-big">
                      <LastSitzungIconLarge />
                    </div>
                    <h2>Letzte Sitzung​</h2>
                    <div style={{ display: "flex", flexDirection: "column" }}>{
                      this.state.letzteSitzung ?
                        <><span><strong>Name:</strong> {this.state.letzteSitzung?.name || ""}</span>
                          <span><strong>Datum:</strong> {this.state.letzteSitzung ? moment(this.state.letzteSitzung.start).format("DD.MM.YYYY - HH:mm") : ''}</span>
                          <span><strong>Status:</strong> {this.getSitzungsStatusString(this.state.letzteSitzungStatus)}</span></> :
                        "Keine letzte Sitzung"
                    }
                    </div>
                  </Card>
                </>}
            </div>
            {this.state.istErsteNutzung && <div>
              <p>Schön, dass Sie sich für die App "Betriebsrat360", Ihren digitalen Sitzungsassistenten entschieden haben!
                Bevor Sie die erste Sitzung planen können, müssen Sie die Pflichtfelder in die Konfiguration vollständig ausgefüllen.
                Alle Details zum Datenschutz erhälten Sie mit einem Klick auf den Menüpunkt "Datenschutz". <br></br><br></br>
                Wir empfehlen außerdem, folgende Punkte für euer Gremium individuell anzupassen:</p>
              <ul>
                <li><b>Einladungstexte:</b> Die App enthält Standardtexte mit allen wichtigen Inhalten, die Sie individuell nach Ihren Standards anpassen können
                </li>
                <li><b>Basis-Tagesordnung:</b> In der Konfiguration können Sie die bei Ihnen in jeder Sitzung wiederkehrenden Tagesordnungspunkte hinterlegen, die Ihnen dann als Vorlage für jede neue Sitzungen angeboten werden.
                  Die App bietet Ihnen den rechtlichen Mindestumfang an, den Sie individuell ergänzen können.</li>
              </ul>
              <p>
                Viel Spaß beim Arbeiten mit dieser App, die Ihnen vieles abnimmt, und Sie rechtssicher von der Einladung über die Durchführung bis hin zur Nachbereitung Protokoll begleitet.
                <br></br>
                Erfasste Verhinderungen werden automatisch in die Anwesenheitsliste übernommen. Das Protokoll wird ebenso automatisch um Ihre individuellen Tagesordnungspunkte ergänzt!
                Während oder nach der Sitzung tragen Sie die Sitzungsinhalte (z.B. Diskussionsinhalte in Stichworten, Beschlussanträge und Abstimmungen) in das bereits vorkonfigurierte Protokoll (Word-Dokument) ein.
                <br></br>
                <br></br>
                <b>Für den Eintrag Ihres Gremiums brauchen Sie ca. 20 Minuten. Passt das jetzt? Dann klicken Sie im Menü auf den Button "Konfiguration", damit starten Sie die Konfiguration.
                  Unsere How-to-Videos unterstützen Sie bei der Handhabung.</b>
              </p>
              <div>
                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/w7K5GcjdSdA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            }
          </> : <UnauthorizedPage />}
      </div >
    );
  }
}

export default MainPage;
