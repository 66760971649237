import { AxiosHeaders } from "axios";
import IMitgliederGremium from "../models/konfiguration/gremium-mitglieder";
import IMitglieder from "../models/konfiguration/mitglieder";
import axios from "./axios-decorator";

export const getMitglieder = async (id: string, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/BenutzerzuordnungDto/${id}`;
    return await axios.get(url, handleTokenAccessFailure);
};
export const getAllMitglieders = async (handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/BenutzerzuordnungDto`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const getAllMitgliedersFromParent = async (id: string, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/BenutzerzuordnungDto`;
    return await axios.get(url, handleTokenAccessFailure, { validateStatus: null, headers: new AxiosHeaders({ GremiumId: id }) });
};

export const postMitglieder = async (mitglieder: IMitgliederGremium, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/BenutzerzuordnungDto`;
    return await axios.post(url, handleTokenAccessFailure, mitglieder);
};

export const deleteMitglieder = async (id: string, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/BenutzerzuordnungDto/${id}`;
    return await axios.delete(url, handleTokenAccessFailure);
};

export const patchMitglieder = async (id: string, propName: string, propValue: any, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/BenutzerzuordnungDto/${id}`;

    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    };
    return await axios.patch(url, handleTokenAccessFailure, data, config);
};