import ITagesordnungspunkt from "../models/tagesordnung/tagesordnungspunkt";
import ITagesordnungspunktVorlage from "../models/tagesordnung/tagesordnungspunktvorlage";


export const readDataFromInputListSitzung = (tagesordnungNode) => {
    const agendaIdInput = document.querySelector(".input-list-wrapper .agenda__id") as HTMLInputElement;
    const agendaId = agendaIdInput.value;
    let tagesordnungArray = Array.from(tagesordnungNode.querySelectorAll('.ui-table.tagesordnungInputList .ui-table__row')).map((row, i) => {
        if (i != 0) {
            const tableRow = row as HTMLElement;
            const idInput = tableRow.querySelector('.input-list__value__id') as HTMLInputElement;
            const sequenzInput = tableRow.querySelector('.input-list__value__sequenz') as HTMLInputElement;
            const nameInput = tableRow.querySelector('.input-list__value__name') as HTMLInputElement;
            const dauerInput = tableRow.querySelector('.input-list__value__dauer') as HTMLInputElement;
            const istBeschlussfassungVorgesehenInput = tableRow.querySelector('.input-list__value__istBeschlussfassungVorgesehen') as HTMLInputElement;
            const istVordefiniertInput = tableRow.querySelector('.input-list__value__istVordefiniert') as HTMLInputElement;
            const vordefinierterTypInput = tableRow.querySelector('.input-list__value__vordefinierterTyp') as HTMLInputElement;
            //TODO: Change selectors with state/variables from the table, no need to use the tagesOrdnungNode anymore, or ref, just pass the object with values here
            const id = idInput.value;
            const sequenz = sequenzInput.value as unknown;
            const name = nameInput.value;
            const dauer = dauerInput.value as unknown;
            const istBeschlussfassungVorgesehen = istBeschlussfassungVorgesehenInput.value;
            const istVordefiniert = istVordefiniertInput.value;
            const vordefinierterTyp = vordefinierterTypInput.value;

            //TODO: Set selectors 
            const item: ITagesordnungspunkt = {
                id: id,
                // tagesordnungId: agendaId,
                createdBy: "",
                createdOn: "",
                modifiedBy: "",
                modifiedOn: "",
                sequenz: Number(sequenz),
                name: name,
                dauer: Number(dauer),
                istBeschlussfassungVorgesehen: istBeschlussfassungVorgesehen == 'true' ? true : false,
                istVordefiniert: istVordefiniert == 'true' ? true : false,
                vordefinierterTyp: Number(vordefinierterTyp),
                 hasAttachments: false,
                 parentItemId: '',
                 responsiblePersonId: "",
                 tagesordnungsNummer: 0,
                 tagesordnungId: '',
            }
            return item;
        } else {
            const item: ITagesordnungspunkt = {
                id: 'id',
                createdBy: "",
                createdOn: "",
                modifiedBy: "",
                modifiedOn: "",
                tagesordnungId: "",
                sequenz: 1,
                name: 'name',
                dauer: 10,
                istBeschlussfassungVorgesehen: false,
                istVordefiniert: false,
                vordefinierterTyp: 0,
                hasAttachments: false,
                parentItemId: '',
                responsiblePersonId: "",
                tagesordnungsNummer: 0,
            }
            return item;
        }
    });
    tagesordnungArray = tagesordnungArray.slice(1);
    //if(typeof tagesordnungArray == ITagesordnungspunktvorlageSample[])
    return tagesordnungArray;
}
export const readDataFromInputList = (tagesordnungNode) => {
    const agendaIdInput = document.querySelector(".input-list-wrapper .agenda__id") as HTMLInputElement;
    const agendaId = agendaIdInput.value;
    let tagesordnungArray = Array.from(tagesordnungNode.querySelectorAll('.ui-table.tagesordnungInputList .ui-table__row')).map((row, i) => {
        if (i != 0) {
            const tableRow = row as HTMLElement;
            const idInput = tableRow.querySelector('.input-list__value__id') as HTMLInputElement;
            const sequenzInput = tableRow.querySelector('.input-list__value__sequenz') as HTMLInputElement;
            const nameInput = tableRow.querySelector('.input-list__value__name') as HTMLInputElement;
            const dauerInput = tableRow.querySelector('.input-list__value__dauer') as HTMLInputElement;
            const istBeschlussfassungVorgesehenInput = tableRow.querySelector('.input-list__value__istBeschlussfassungVorgesehen') as HTMLInputElement;
            const istVordefiniertInput = tableRow.querySelector('.input-list__value__istVordefiniert') as HTMLInputElement;
            const vordefinierterTypInput = tableRow.querySelector('.input-list__value__vordefinierterTyp') as HTMLInputElement;
            //TODO: Change selectors with state/variables from the table, no need to use the tagesOrdnungNode anymore, or ref, just pass the object with values here
            const id = idInput.value;
            const sequenz = sequenzInput.value as unknown;
            const name = nameInput.value;
            const dauer = dauerInput.value as unknown;
            const istBeschlussfassungVorgesehen = istBeschlussfassungVorgesehenInput.value;
            const istVordefiniert = istVordefiniertInput.value;
            const vordefinierterTyp = vordefinierterTypInput.value;

            //TODO: Set selectors 
            const item: ITagesordnungspunktVorlage = {
                
                id: id,
                // tagesordnungId: agendaId,
                createdBy: "",
                createdOn: "",
                modifiedBy: "",
                modifiedOn: "",
                sequenz: Number(sequenz),
                name: name,
                dauer: Number(dauer),
                istBeschlussfassungVorgesehen: istBeschlussfassungVorgesehen == 'true' ? true : false,
                istVordefiniert: istVordefiniert == 'true' ? true : false,
                vordefinierterTyp: Number(vordefinierterTyp),
                 hasAttachments: false,
                 parentItemId: '',
                 responsiblePersonId: "",
                 tagesordnungsNummer: 0,
                gremiumId: '',
            }
            return item;
        } else {
            const item: ITagesordnungspunktVorlage = {
                id: 'id',
                createdBy: "",
                createdOn: "",
                modifiedBy: "",
                modifiedOn: "",
                gremiumId: "",
                sequenz: 1,
                name: 'name',
                dauer: 10,
                istBeschlussfassungVorgesehen: false,
                istVordefiniert: false,
                vordefinierterTyp: 0,
                hasAttachments: false,
                parentItemId: '',
                responsiblePersonId: "",
                tagesordnungsNummer: 0,
            }
            return item;
        }
    });
    tagesordnungArray = tagesordnungArray.slice(1);
    //if(typeof tagesordnungArray == ITagesordnungspunktvorlageSample[])
    return tagesordnungArray;
}