import "./footer.scss";
import Image from "../../../assets/ifb-footer-logo.svg";
const Footer = () => {
  return (
    <div className="footer">
      <div>
        <a href="https://www.ifb.de/service/betriebsrat360#datenschutz">Datenschutz</a>
        <span> | </span>
        <a href="https://www.ifb.de/service/impressum">Impressum</a>
      </div>
      <div className="footer-text-logo">
        <img src={Image} alt="ifbFooterLogo" />
        Institut zur Fortbildung von Betriebsräten GmbH & Co. KG © 2023
      </div>
    </div>
  );
};

export default Footer;
