import axios from "./axios-decorator";
import IProtocol from "../models/agenda/IProtocol";

export const postProtocol = async (protocol: IProtocol, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/ProtokollDto';
    return await axios.post(url, handleTokenAccessFailure, protocol);
}
export const deleteProtocol = async (protokolId: string, handleTokenAccessFailure = (error: string) => window.alert(error)) => {
    const url = '/api/ProtokollDto/'+ protokolId;
    return await axios.delete(url, handleTokenAccessFailure);
}