import { Text, Field } from "@fluentui/react-components";
import React from "react";
import ISiztungFormBaseProps from "./sitzung-form-base-props";
import * as microsoftTeams from "@microsoft/teams-js";
import { StatusCodes } from "http-status-codes";
import ISitzungFormBaseState from "./sitzung-form-base-state";
import { getMeeting } from "../../../api/meeting-dto-api";
import { getTeamsChannel } from "../../../api/teams-channel-dto-api";
import InfoBox from "../../common/info-box/infoBox";

interface IMeetingLinksProps extends ISiztungFormBaseProps {

}

interface IMeetingLinksState extends ISitzungFormBaseState {
    channelWebUrl: string,
    sharepointWebUrl: string,
}

class MeetingLinks extends React.Component<IMeetingLinksProps, IMeetingLinksState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            showLoader: false,
            channelWebUrl: '',
            sharepointWebUrl: '',
        }
    }

    componentDidMount = async () => {
        await this.loadData();
        this.props.loadMeetingStepStatuses();
        this.props.setSaveAndResetFunctions(undefined);
    }

    loadData = async () => {
        this.props.setIsLoading(true)
        const meetingDetailsResponse = await getMeeting(this.props.meetingId);
        if (meetingDetailsResponse.status == StatusCodes.OK) {
            const teamId = meetingDetailsResponse.data.teamId;
            const teamsChannelId = meetingDetailsResponse.data.teamsChannelId;

            const meetingLinksResponse = await getTeamsChannel(teamId, teamsChannelId);
            this.setState({
                sharepointWebUrl: meetingLinksResponse.data.sharepointUrl,
                channelWebUrl: meetingLinksResponse.data.webUrl,
            });
        }
        this.props.setIsLoading(false)

    }

    goToMeetingChannel = () => {
        microsoftTeams.app.openLink(this.state.channelWebUrl);
    }

    save = null;

    render() {
        return (<>
            <div className="meeting-links__wrapper">
                <InfoBox label={"Letzter Check: Über die Links gelangen Sie direkt zum Sitzungskanal oder zum Sharepoint. Hier liegen die Dokumente zur Sitzung, die über die App generiert wurden. Zusätzlich können Sie weitere Anlagen ablegen."}></InfoBox>
                <Text className='sitzung-form__section-title' as='h3'>Sitzungsunterlagen prüfen</Text>
                <Field>
                    <p>Tagesordnung, Protokoll, Anwesenheitsliste sowie evtl. weitere Unterlagen zur Sitzung finden Sie <a style={{ cursor: 'pointer' }} onClick={this.goToMeetingChannel} ><b>hier</b></a> (Link zum Sitzungskanal, dort liegt alles unter der Registerkarte Dateien) oder <a href={this.state.sharepointWebUrl} target='_blank'><b>hier</b></a> (Link zum Sharepoint).</p>
                    <p><strong>Wichtig: </strong>Damit die Dokumente in der korrekten Formatierung erscheinen und komfortabel zu bearbeiten sind, immer in der Desktop-App öffnen</p>
                </Field>
            </div>
        </>)
    }
}

export default MeetingLinks;