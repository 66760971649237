import { AxiosHeaders } from "axios";
import IAdministrator from "../models/konfiguration/administrator";
import IAssistent from "../models/konfiguration/assistent";
import IEinladungKonfig from "../models/konfiguration/einladung-konfig";
import axios from "./axios-decorator";

export const getGremiumKonfig = async (id: string, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumDetailsDto/${id}`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const getAllGremiumKonfigs = async (handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumDetailsDto`;

    return await axios.get(url, handleTokenAccessFailure);
};

export const getGremiumDefaults = async (handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumDetailsDto/defaults`;

    return await axios.get(url, handleTokenAccessFailure);
};

export const postGremiumAdministrator = async (admin: IAdministrator, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumAdministratorDto`;
    return await axios.post(url, handleTokenAccessFailure, admin);
};

export const getGremiumAdministrators = async (id: string, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumAdministratorDto/${id}`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const deleteGremiumAdministrators = async (id: string, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumAdministratorDto/${id}`;
    return await axios.delete(url, handleTokenAccessFailure);
};

export const getAllGremiumAdministrators = async (handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumAdministratorDto`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const getAllGremiumAssitents = async (handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumAssistantDto`;
    return await axios.get(url, handleTokenAccessFailure);
};
export const postGremiumAssitents = async (assistent: IAssistent, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumAssistantDto`;
    return await axios.post(url, handleTokenAccessFailure, assistent);
};
export const deleteGremiumAssitents = async (id: string, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumAssistantDto/${id}`;
    return await axios.delete(url, handleTokenAccessFailure);
};

export const patchGremiumKonfig = async (id: string, propName: string, propValue: any, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/GremiumDetailsDto/${id}`;

    const data = [{
        "op": "replace",
        "path": "/" + propName,
        "value": propValue,
        "from": ""

    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    }
    return await axios.patch(url, handleTokenAccessFailure, data, config);
};

export const getAllEinladungKonfig = async (handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = '/api/EinladungVorlageDto';
    return await axios.get(url, handleTokenAccessFailure);
};
export const getEinladungKonfigById = async (id: string, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/EinladungVorlageDto/${id}`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const getEinladungDefaults = async (handleTokenAccessFailure: (error: string) => void) => {
    let url = '/api/EinladungVorlageDto/defaults';
    return await axios.get(url, handleTokenAccessFailure);
};

export const postEinladungKonfig = async (einladungDetails: IEinladungKonfig, handleTokenAccessFailure: (error: string) => void) => {
    let url = '/api/EinladungVorlageDto';
    return await axios.post(url, handleTokenAccessFailure, einladungDetails);
};
export const patchEinladungKonfig = async (id: string, propName: string, propValue: any, handleTokenAccessFailure: (error: string) => void) => {
    let url = `/api/EinladungVorlageDto/${id}`;
    const data = [{
        "op": "replace",
        "path": "/" + propName,
        "value": propValue,
        "from": ""

    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    }
    return await axios.patch(url, handleTokenAccessFailure, data, config);
};

export const getTagesordnungPunktKonfig = async (handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = 'api/TagesordnungpunktVorlageDto';
    return await axios.get(url, handleTokenAccessFailure);
};

export const getTagesordnungPunktKonfigGremium = async (id: string, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = 'api/TagesordnungpunktVorlageDto';
    return await axios.get(url, handleTokenAccessFailure, { headers: new AxiosHeaders({ GremiumId: id }) });
};

export const updateTagesordnungPunktKonfig = async (id: string, tagesordnungPunkt, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/TagesordnungpunktVorlageDto/${id}`;
    return await axios.put(url, handleTokenAccessFailure, tagesordnungPunkt);
};

export const getTagesordnungPunktKonfigDefaults = async (handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = '/api/TagesordnungpunktVorlageDto/defaults';
    return await axios.get(url, handleTokenAccessFailure);
};

export const updateProtokollKonfig = async (propName: string, propValue: string, id: string, handleTokenAccessFailure: (error: string) => void) => {
    const data = [{
        "op": "replace",
        "path": "/" + propName,
        "value": propValue,
        "from": ""

    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    }
    let url = `/api/GremiumDetailsDto/${id}`;
    return await axios.patch(url, handleTokenAccessFailure, data, config);
};

export const patchProtokollSitzung = async (propName: string, propValue, id: string, handleTokenAccessFailure: (error: string) => void) => {
    const data = [{
        "op": "replace",
        "path": "/" + propName,
        "value": propValue,
        "from": ""

    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    }
    let url = `/api/ProtokollDto/${id}`;
    return await axios.patch(url, handleTokenAccessFailure, data, config);
};
