import * as React from 'react';
import './radio-group.scss';

interface IRadioGroupProps {
    value?;
    name?;
    dName?;
    id?;
    valueArray;
    isVotingInput?;
    disabled?;
}

interface IRadioGruopState {
    value;
    initialUpdate: boolean;
    radioGroupRef;
    //id;

}

class RadioGroup extends React.Component<IRadioGroupProps, IRadioGruopState> {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            initialUpdate: true,
            radioGroupRef: React.createRef(),
        }
    }

    async componentDidMount() {
        if (this.props.isVotingInput) {
            await this.initValue();
        }
    }

    async componentDidUpdate() {
        await this.initValue();
    }

    initValue = async () => {
        if (this.props.value && this.state.initialUpdate && this.props.value !== this.state.value) {
            let selectedRadioButton = this.state.radioGroupRef.current.querySelector(`input[value='${this.props.value}']`) as HTMLInputElement;
            if (selectedRadioButton) {
                selectedRadioButton.checked = true;
            }
            await this.setState({
                value: this.props.value,
            });
        } else if (this.props.isVotingInput && this.state.initialUpdate) {
            let selectedRadioButton = this.state.radioGroupRef.current.querySelector(`input[value='${this.props.value}']`) as HTMLInputElement;
            if (selectedRadioButton) {
                selectedRadioButton.checked = true;
            }
        }
        if (this.props.disabled) {
            let allRadioButtons = this.state.radioGroupRef.current.querySelectorAll(".radio-group__input");
            allRadioButtons.forEach(rb => rb.disabled = true);
        }
    }

    handleInput = async (event) => {
        let radioButton = event.target as HTMLInputElement;
        radioButton.checked = true;
        this.setState({
            initialUpdate: false,
            value: radioButton.value
        });
    }

    render() {
        return (
            <div className='radio-group-wrapper' ref={this.state.radioGroupRef} data-name={this.props.dName} data-id={this.props.id}>
                {this.props.valueArray.map((v, i) => <label>
                    <input
                        className='radio-group__input'
                        type='radio'
                        id={v.id}
                        // name={this.props.name}
                        value={v.id}
                        onInput={(e) => this.handleInput(e)}
                        disabled={this.props.disabled}
                        checked={v.id === this.state.value}
                        onChange={(e) => this.handleInput(e)}
                    />
                    {v.displayName}</label>
                )}
            </div>
        );
    }
}

export default RadioGroup;