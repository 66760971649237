import { AxiosHeaders } from "axios";
import IChecklistItem from "../models/nachbereiten/IchecklistItem";
import axios from "./axios-decorator";

export const getChecklistTemplate = async (checklistItem: IChecklistItem, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemTemplateDto/${checklistItem.id}`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const getAllChecklistTemplates = async (handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemTemplateDto`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const getGremiumCheckliste = async (id: string, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemTemplateDto/gremium/${id}`;
    return await axios.get(url, handleTokenAccessFailure);
};

export const postChecklistTemplateItem = async (checklistItem, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemTemplateDto`;
    return await axios.post(url, handleTokenAccessFailure, checklistItem);
}

export const putChecklistTemplateItem = async (checklistItem: IChecklistItem, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemTemplateDto/${checklistItem.id}`;
    return await axios.put(url, handleTokenAccessFailure, checklistItem);
}

export const deleteChecklistTemplateItem = async (checklistId: string, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemTemplateDto/${checklistId}`;
    return await axios.delete(url, handleTokenAccessFailure);
}

export const patchChecklistTemplateItem = async (id: string, propName: string, propValue: any, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemTemplateDto/${id}`;
    const data = [{
        "op": "replace",
        "path": propName,
        "value": propValue
    }];
    const config = {
        headers: new AxiosHeaders({
            'Content-Type': 'application/json-patch+json',
        })
    };
    return await axios.patch(url, handleTokenAccessFailure, data, config);
}

export const getChecklistGremium = async (id: string, handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemTemplateDto/gremium/${id}`;
    return await axios.get(url, handleTokenAccessFailure);
}

export const getDefaultChecklistValues = async (handleTokenAccessFailure: (error: string) => void = window.alert) => {
    let url = `/api/ChecklistItemTemplateDto/defaults`;
    return await axios.get(url, handleTokenAccessFailure);
}

