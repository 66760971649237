import { Text, Field, Input, Tooltip } from "@fluentui/react-components";
import React from "react";
import TagesordnungInputList from "../../common/input-list/tagesordnung-input-list";
import { Guid } from "guid-typescript";
import { StatusCodes } from "http-status-codes";
import ITagesordnung from "../../../models/tagesordnung/tagesordnung";
import { userHasPermission } from "../../../Helpers/permissionHelper";
import { readDataFromInputListSitzung } from "../../../Helpers/tagesordnungHelper";
import IMeetingFormBaseProps from "./sitzung-form-base-props";
import IProtokoll from "../../../models/protokoll/protokoll";
import { Protokollstatus } from "../../../enums/protokollstatus.enum";
import { cloneDeep, isEqual, pick } from "lodash";
import IMeetingFormBaseState from "./sitzung-form-base-state";
import { ConfirmResetToDefault, RequiredFieldErrorMessage } from "../../../constants/textLabels";
import { deleteAgendaItem, getAgenda, getAgendaItems, getProtokoll, getSitzungProtokoll, patchAgenda, postAgenda, postAgendaItem, putAgendaItem, putAgendaItems } from "../../../api/agenda-dto-api";
import { getTagesordnungPunktKonfig, patchProtokollSitzung } from "../../../api/konfiguration-api";
import ITagesordnungspunkt from "../../../models/tagesordnung/tagesordnungspunkt";
import { MeetingFormStepStatus } from "../../../enums/meeting-form-step-status.enum";
import { IMeetingFormBaseFunctions } from "./sitzung-form-base-functions";
import IProtocol from "../../../models/agenda/IProtocol";
import { deleteProtocol, postProtocol } from "../../../api/protocol-dto-api";
import { getGremiumDetailDto } from "../../../api/konfig-tagesordnungspunktvorlage-api";
import { handleTokenAccessFailure } from "../../../Helpers/apiHelper";
import "./meeting-form.scss"
import InfoBox from "../../common/info-box/infoBox";
import ConfirmDialog from "../../common/dialog/confirm-dialog";
import ResetIconButton from "../../common/resetIcon/ResetIcon";
import { getAttendanceList, patchAttendanceList } from "../../../api/attendance-list-dto-api";
import { getInvitation, patchInvitation } from "../../../api/invitations-dto-api";
import { InfoIcon } from "../../../utils/icons";

interface IAgendaCreationProps extends IMeetingFormBaseProps {
  gremiumId: string;
  setIsFormValid: Function;
  resetPhase: Function;
}

interface IAgendaCreationState extends IMeetingFormBaseState {
  agenda: ITagesordnung;
  agendaInitial: ITagesordnung;
  agendaPrev: ITagesordnung;
  protocol: IProtokoll;
  agendaInputRef;
  resetRowsFunction?: Function;
  protokollKonfig: string;
  protokollId: string;
  changeAgendaDialog: boolean;
  typingTimer: NodeJS.Timeout | undefined;
  confirmDialogRef;
  invitationId: string
}

class AgendaCreation extends React.Component<IAgendaCreationProps, IAgendaCreationState> implements IMeetingFormBaseFunctions {
  constructor(props: any) {
    super(props);

    this.state = {
      invitationId: '',
      confirmDialogRef: React.createRef(),
      changeAgendaDialog: false,
      isLoading: false,
      showLoader: false,
      agenda: {
        id: Guid.EMPTY,
        name: "",
        tagesordnungstatus: MeetingFormStepStatus.Draft,
        tagesordnungspunkte: [],
        sitzungId: this.props.meetingId,
      },
      agendaInitial: {
        id: Guid.EMPTY,
        name: "",
        tagesordnungstatus: MeetingFormStepStatus.Draft,
        tagesordnungspunkte: [],
        sitzungId: this.props.meetingId,
      },
      agendaPrev: {
        id: Guid.EMPTY,
        name: "",
        tagesordnungstatus: MeetingFormStepStatus.Draft,
        tagesordnungspunkte: [],
        sitzungId: this.props.meetingId,
      },
      protocol: {
        id: Guid.parse(Guid.EMPTY),
        name: "",
        protokollstatus: Protokollstatus.Entwurf,
        tagesordnungspunkte: [],
      },
      agendaInputRef: React.createRef(),
      protokollKonfig: '',
      protokollId: '',
      typingTimer: undefined,
    };
  }

  componentDidMount = async () => {
    //this.props.setDataHasChanged(false);
    this.props.setIsFormValid(true)
    this.props.setIsLoading(true);
    await this.loadData();
    const { data } = await getSitzungProtokoll(this.props.meetingId);
    this.setState({ protokollId: data[0].id, protokollKonfig: data[0].name })
    this.props.setSaveAndResetFunctions(this.saveDraft);
    // await this.props.loadMeetingStepStatuses();
    this.props.setIsLoading(false);
  };

  loadData = async () => {
    let [responseAgendaHead, responseAgendaItems] = await Promise.all([
      getAgenda(this.props.meetingId),
      getAgendaItems(this.props.meetingId),
    ]);

    if (
      responseAgendaHead.status == StatusCodes.OK &&
      responseAgendaItems.status == StatusCodes.OK
    ) {
      let agenda: ITagesordnung = {
        id: Guid.EMPTY,
        name: "",
        tagesordnungstatus: MeetingFormStepStatus.Draft,
        tagesordnungspunkte: [],
        sitzungId: this.props.meetingId,
      };
      let protocol: IProtocol = {
        id: Guid.EMPTY,
        name: "",
        sitzungId: Guid.EMPTY,
        protokollstatus: MeetingFormStepStatus.Draft
      };
      if (responseAgendaHead.data.length > 0) {
        agenda = responseAgendaHead.data[0];
        responseAgendaItems.data.sort((a, b) => {
          return a.sequenz - b.sequenz;
        });
        agenda.tagesordnungspunkte = responseAgendaItems.data;
      } else {
        const configAgendaResponseItems = await getTagesordnungPunktKonfig(handleTokenAccessFailure);
        const gremiumDetailId = this.props.gremiumId
        const getGremiumData = await getGremiumDetailDto(gremiumDetailId, handleTokenAccessFailure)
        const gremiumDetail = getGremiumData.data
        const agendaName = gremiumDetail?.tagesordnungsname
        agenda.name = agendaName;
        agenda.sitzungId = this.props.meetingId;
        agenda.tagesordnungstatus = MeetingFormStepStatus.ReadyForProcessing;
        agenda.showDauer = gremiumDetail.showDauerOnTagesordnung
        agenda.showResponsiblePerson = gremiumDetail.showResponsiblePersonOnTagesordnung
        agenda.showHasAttachments = gremiumDetail.showHasAttachmentsOnTagesordnung
        const postAgendaResponse = await postAgenda(agenda);
        if (agenda.tagesordnungstatus <= MeetingFormStepStatus.ReadyForProcessing && agenda.id) {
          await patchAgenda(postAgendaResponse.data.id, 'tagesordnungstatus', MeetingFormStepStatus.ReadyForProcessing);
          this.props.loadMeetingStepStatuses()
        }

        const protocolName = getGremiumData.data.protokollname;
        protocol.name = protocolName;
        protocol.sitzungId = this.props.meetingId;
        protocol.protokollstatus = MeetingFormStepStatus.ReadyForProcessing;
        const postProtocolResponse = await postProtocol(protocol);

        if (postAgendaResponse.status == StatusCodes.OK && postProtocolResponse.status == StatusCodes.OK) {
          const configAgendaItems = configAgendaResponseItems.data as ITagesordnungspunkt[];
          configAgendaItems.sort((a, b) => {
            if (a.parentItemId) {
              return 1;
            }

            if (b.parentItemId) {
              return -1;
            }

            return a < b ? -1 : 1;
          });
          let postAgendaItemsStatus = StatusCodes.OK;
          const agendaItemResponses: any[] = []
          const tempParentItems: any[] = []

          for await (const item of configAgendaItems) {
            if (!item.parentItemId) {
              item.tagesordnungId = postAgendaResponse.data.id;
              const postAgendaItemResponse = await postAgendaItem(item);
              if (postAgendaItemResponse.status !== StatusCodes.OK) {
                postAgendaItemsStatus = postAgendaItemResponse.status;
              }
              let newItem: any = postAgendaItemResponse.data
              newItem.oldId = item.id
              tempParentItems.push(newItem)
              agendaItemResponses.push(postAgendaItemResponse.data)
            }
            else {
              item.tagesordnungId = postAgendaResponse.data.id;
              const newParentId = tempParentItems.find(t => t.oldId === item.parentItemId).id
              item.parentItemId = newParentId
              const postAgendaItemResponse = await postAgendaItem(item);
              if (postAgendaItemResponse.status !== StatusCodes.OK) {
                postAgendaItemsStatus = postAgendaItemResponse.status;
              }
            }
          }

          if (postAgendaItemsStatus === StatusCodes.OK) {
            agenda = postAgendaResponse.data;
            agenda.tagesordnungspunkte = agendaItemResponses;
          }
        }
      }
      this.setState({
        agenda: agenda,
        agendaInitial: cloneDeep(agenda),
        agendaPrev: cloneDeep(agenda),
      });
    }
  };

  onAgendaChange = (event) => {
    const target: HTMLElement = event.target;
    if (target.classList.contains("tagesordnungInputList")) {
      const agendaItems: ITagesordnungspunkt[] = readDataFromInputListSitzung(
        this.state.agendaInputRef.current.componentNode
      ) as ITagesordnungspunkt[];
      let agenda = this.state.agenda;
      let agendaPrev = this.state.agendaPrev;
      let prevItems: ITagesordnungspunkt[] = cloneDeep(
        agenda.tagesordnungspunkte
      ) as ITagesordnungspunkt[];
      agenda.tagesordnungspunkte = agendaItems;
      agendaPrev.tagesordnungspunkte = prevItems;
      this.setState(
        { agenda: agenda, agendaPrev: agendaPrev },
        () => this.saveDraft()
      );
    } else {
      event.stopPropagation();
    }
  };

  setResetRowsFunction = (resetRowsFunction: Function) => {
    this.setState({ resetRowsFunction: resetRowsFunction });
  };

  // setDataHasChanged = () => {
  //   if (isEqual(this.state.agenda, this.state.agendaInitial)) {
  //     this.props.setDataHasChanged(false);
  //   } else {
  //     this.props.setDataHasChanged(true);
  //   }
  // }

  setDataChanged = async () => {
    this.props.setDataHasChanged(true);
    this.resetToPhaseOne()
    await patchAgenda(this.state.agenda.id, 'tagesordnungstatus', MeetingFormStepStatus.NeedsReprocessing);
    await patchProtokollSitzung('protokollstatus', MeetingFormStepStatus.NeedsReprocessing, this.state.protokollId, handleTokenAccessFailure)
  }

  setOrderChanged = async () => {
    this.props.setOrderHasChanged(true);
    this.resetToPhaseOne()
    await patchAgenda(this.state.agenda.id, 'tagesordnungstatus', MeetingFormStepStatus.NeedsReprocessing);
    await patchProtokollSitzung('protokollstatus', MeetingFormStepStatus.NeedsReprocessing, this.state.protokollId, handleTokenAccessFailure)
  }

  isFormValid = (field: string = '') => {
    return true;
  }

  handleTokenAccessFailure = (error: string) => {
    alert(error);
  };


  onChange = async (event: any) => {
    let name = event.target.value;

    this.setState({ protokollKonfig: name }, () => {
      if (this.state.typingTimer) {
        clearTimeout(this.state.typingTimer);
      }
      this.setState({
        typingTimer: setTimeout(async () => {
          await patchProtokollSitzung(
            "name",
            name,
            this.state.protokollId,
            handleTokenAccessFailure
          )
          this.setDataChanged();
        }, 1000),
      });
    });
  };

  resetToPhaseOne = async () => {
    const getAttendanceListResponse = await getAttendanceList(
      this.props.meetingId, handleTokenAccessFailure
    );
    const { data } = await getSitzungProtokoll(this.props.meetingId);
    const agenda = await getAgenda(this.props.meetingId)
    let invId
    if (!this.state.invitationId) {
      const resp = await getInvitation(this.props.meetingId.toString(), handleTokenAccessFailure)
      if (resp.data.length) {
        invId = resp.data[0].id
        this.setState({ invitationId: resp.data[0].id })
      }
    } else {
      invId = this.state.invitationId
    }
    if (getAttendanceListResponse.data.length > 0) {
      await patchAttendanceList(getAttendanceListResponse.data[0].id, 'anwesenheitslistenstatus', MeetingFormStepStatus.Draft);
    }
    if (invId) {
      await patchInvitation(invId, 'einladungsstatus', MeetingFormStepStatus.ReadyForProcessing, handleTokenAccessFailure)
    }
    if (agenda.data.length > 0) {
      await patchAgenda(agenda.data[0].id, 'tagesordnungstatus', MeetingFormStepStatus.NeedsReprocessing);
    }
    if (data.length > 0) {
      await patchProtokollSitzung('protokollstatus', MeetingFormStepStatus.NeedsReprocessing, data[0].id, handleTokenAccessFailure)
    }
    this.props.resetPhase()
  }

  isFormDisabled(): boolean {
    const ret = !userHasPermission(
      this.props.userPermissions,
      "TagesordnungCreateUpdateDelete"
    )
      ||
      this.props.meetingHasStarted() || (this.props.meetingCompleted ? true : false)

    return ret;
  }

  saveDraft = async (propName: string | undefined = undefined, propValue: any = undefined, showLoader: boolean = false) => {
    if (isEqual(this.state.agenda, this.state.agendaPrev)) {
      return true;
    }
    // this.setDataHasChanged();
    if (showLoader) { this.props.setIsLoading(true); }

    const agendaItems = this.state.agenda.tagesordnungspunkte;
    const prevAgendaItems = this.state.agendaPrev.tagesordnungspunkte;
    let statusCode = StatusCodes.OK;
    if (isEqual(agendaItems, prevAgendaItems)) {
      return true;
    } else {
      let changedAgendaItems: ITagesordnungspunkt[] = new Array();
      let newAgendaItems: ITagesordnungspunkt[] = new Array();
      let toDeleteAgendaItems: ITagesordnungspunkt[] = new Array();

      agendaItems.forEach((agendaItem: ITagesordnungspunkt) => {
        const prevAgendaItem = prevAgendaItems.find(
          (item) => item.id == agendaItem.id
        ) as ITagesordnungspunkt;
        const pickedAttributes = ['vordefinierterTyp', 'tagesordnungId', 'sequenz', 'name', 'istVordefiniert', 'istBeschlussfassungVorgesehen', 'id', 'dauer'];
        if (prevAgendaItem == undefined) {
          newAgendaItems.push(agendaItem);
        } else if (isEqual(pick(agendaItem, pickedAttributes), pick(prevAgendaItem, pickedAttributes)) == false) {
          changedAgendaItems.push(agendaItem);
        }
      });
      prevAgendaItems.forEach(prevAgendaItem => {
        const agendaItem = agendaItems.find(agendaItem => prevAgendaItem.id === agendaItem.id);
        if (agendaItem == undefined) {
          toDeleteAgendaItems.push(prevAgendaItem);
        }
      });

      const responsePromises = [
        ...changedAgendaItems.map((item) => putAgendaItem(item)),
        ...newAgendaItems.map((item) => postAgendaItem(item)),
        ...toDeleteAgendaItems.map((item) => deleteAgendaItem(item.id)),
      ];
      const responses = await Promise.all(responsePromises);
      responses.forEach((response) => {
        if (response.status != StatusCodes.OK) {
          statusCode = StatusCodes.BAD_REQUEST;
        }
      });

      if (this.state.agenda.tagesordnungstatus == MeetingFormStepStatus.Processed) {
        //Set agenda status to NeedsReprocessing
        const patchAgendaStatusResponse = await patchAgenda(this.state.agenda.id, 'tagesordnungstatus', MeetingFormStepStatus.NeedsReprocessing);
        if (patchAgendaStatusResponse.status != StatusCodes.OK) {
          statusCode = patchAgendaStatusResponse.status;
        }
      }
    }

    if (showLoader) { this.props.setIsLoading(false); }

    if (statusCode === StatusCodes.OK) {
      return true;
    } else {
      window.alert("Erstellen der Tagesordnung Entwurf ist fehlgeschlagen!");
      this.setState({ agenda: cloneDeep(this.state.agendaPrev) }, () => {
        if (this.state.resetRowsFunction) {
          this.state.resetRowsFunction();
        }
      });
      return false;
    }
  };

  // reset = async (showLoader: boolean = false) => {
  //   const agenda = cloneDeep(this.state.agendaInitial);
  //   const agendaPrev = cloneDeep(this.state.agenda);
  //   await this.setState({ agenda: agenda, agendaPrev: agendaPrev });
  //   const result = await this.saveDraft(undefined, undefined, showLoader);
  //   this.props.setDataHasChanged(false);
  //   return result;
  // };

  resetToDefaultProtokoll = async () => {
    this.state.confirmDialogRef.current.showConfirmDialog(ConfirmResetToDefault, async () => {
      let protocol: IProtocol = {
        id: Guid.EMPTY,
        name: "",
        sitzungId: Guid.EMPTY,
        protokollstatus: MeetingFormStepStatus.Draft
      };
      await deleteProtocol(this.state.protokollId, handleTokenAccessFailure)
      const gremiumDetailId = this.props.gremiumId
      const getGremiumData = await getGremiumDetailDto(gremiumDetailId, handleTokenAccessFailure)
      const protocolName = getGremiumData.data.protokollname;
      protocol.name = protocolName;
      protocol.sitzungId = this.props.meetingId;
      protocol.protokollstatus = MeetingFormStepStatus.NeedsReprocessing;
      const { data } = await postProtocol(protocol);
      this.setState({ protokollId: data.id, protokollKonfig: data.name })
    })
    this.resetToPhaseOne()
  }


  render() {
    return (
      <>
        <InfoBox label={"Die Tagesordnung ist mit Ihren Standard-TOP's aus der Konfiguration vorbefüllt. Diese lassen sich jederzeit in der Konfiguration ändern. Für die aktuelle Sitzung können Sie TOPs hinzufügen, die bestehenden überschreiben und über die Pfeile die Reihenfolge ändern. Die Tagesordnung wird erst im Sitzungskanal abgelegt, wenn Sie die Phase Sitzungsvorbereitung abschließen. Bei Ergänzungen der Tagesordnung nach Abschluss der Sitzungsvorbereitung wird eine neue Version mit Versionsnummer erstellt. So behalten alle den Überblick. Gleichzeitig wird das Protokoll als Word automatisch aus der TO als Word generiert und aktualisiert sich bei Änderungen mit erneutem klicken des Abschließen-Buttons."}></InfoBox>
        {
          < >
            <Text className="sitzung-form__section-title" as="h3">
              Tagesordnung und Protokoll
            </Text>
            <ConfirmDialog
              ref={this.state.confirmDialogRef} />
            {/* <AlertDialog
              isDialogHidden={!this.state.changeAgendaDialog}
              closeFunction={() => { this.setState({ changeAgendaDialog: false }) }}
              message="Ihre Änderungen sind bisher nur in der App gespeichert. Für eine Aktualisierung der Unterlagen im Kanal klicken Sie bitte Abschließen."
            /> */}
            <Field onInput={this.onAgendaChange}>
              <TagesordnungInputList
                ref={this.state.agendaInputRef}
                isSitzung={this.state.agenda.sitzungId}
                disabled={this.isFormDisabled()}
                id={this.state.agenda.id}
                data={cloneDeep(this.state.agenda.tagesordnungspunkte)}
                //createErrorNotification={this.createErrorNotification}
                setResetRowsFunction={this.setResetRowsFunction}
                dataHasChanged={this.setDataChanged}
                orderHasChanged={this.setOrderChanged}
              />
            </Field>
            <Field label={<>Protokoll{" "}
              <Tooltip
                relationship="label"
                content="Machen Sie sich keine Gedanken über das Protokoll. Dieses wird automatisch aus der Tagesordnung erstellt. Hier brauchen Sie in der Regel nichts ändern."
              ><span><InfoIcon /></span></Tooltip>
              <ResetIconButton disabled={this.isFormDisabled()} onClick={this.resetToDefaultProtokoll} />
            </>}
              validationMessage={this.state.protokollKonfig.trim() === ""
                ? RequiredFieldErrorMessage
                : ""}
              validationState={this.state.protokollKonfig.trim() === ""
                ? "error"
                : "none"}
            >
              <Input
                style={{ width: "100%" }}
                value={this.state.protokollKonfig}
                onChange={this.onChange}
                disabled={this.isFormDisabled()}
              />
            </Field>
          </>
        }
      </>
    );
  }
}

export default AgendaCreation;
