import IGraphUser from "../models/graph-user";

export const addItemWithoutDuplicatesID = (
  newItem: IGraphUser,
  items: IGraphUser[]
): IGraphUser[] => {
  const isDuplicate = items.some((item) => item.id === newItem.id);

  if (!isDuplicate) {
    return [...items, newItem];
  } else {
    return items;
  }
};

export const mergeArraysWithoutDuplicatesID = (
  array1: IGraphUser[],
  array2: IGraphUser[]
): IGraphUser[] => {
  const mergedArray = [...array1, ...array2];

  const uniqueItems = mergedArray.reduce((acc, current) => {
    if (!acc.has(current.id)) {
      acc.set(current.id, current);
    }
    return acc;
  }, new Map());

  return Array.from(uniqueItems.values());
};
