import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "@fluentui/react-components";

function handleLogin(instance) {
  instance
    .loginPopup(loginRequest)
    .then((result: any) => {
      console.log(result);
    }).then(() => window.location.reload())
    .catch((e) => {
      console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const BrowserSignInButton = () => {
  const { instance } = useMsal();

  return (
    <Button
      appearance="primary"
      style={{ width: "200px" }}
      onClick={() => handleLogin(instance)}
    >
      Anmelden
    </Button>
  );
};
