import { AxiosRequestConfig, InternalAxiosRequestConfig, AxiosResponse, default as axios, default as baseAxios } from "axios";
//Average network timeout in milliseconds.
axios.defaults.timeout = 60000;

//Application base URI.
axios.defaults.baseURL = window.location.origin;

/// Decorator for Browser-Authentication (cannot be used in Teams)
export class AxiosJWTBrowserDecorator {
    /**
    * Post data to API
    * @param  {String} url Resource URI
    * @param  {String} accessToken AccessToken.
    * @param  {Object} data Request body data
    */
    public async post<T = any, R = AxiosResponse<T>>(
        url: string,
        accessToken: string,
        data?: any,
        config?: InternalAxiosRequestConfig
    ): Promise<R> {
        try {
            config = await this.setupAuthorizationHeader(accessToken, config);
            return await axios.post(url, data, config);
        } catch (error) {
            return Promise.resolve(this.handleError(error));
        }
    }

    /**
    * Delete data to API
    * @param  {String} url Resource URI
    * @param  {String} accessToken Access Token
    */
    public async delete<T = any, R = AxiosResponse<T>>(
        url: string,
        accessToken: string,
        config?: InternalAxiosRequestConfig
    ): Promise<R> {
        try {
            config = this.setupAuthorizationHeader(accessToken, config);
            return await axios.delete(url, config);
        } catch (error) {
            return Promise.resolve(this.handleError(error));
        }
    }

    /**
    * Put data to API
    * @param  {String} url Resource URI
    * @param  {String} accessToken Access Token
    * @param  {Object} data Request body data
    */
    public async put<T = any, R = AxiosResponse<T>>(
        url: string,
        accessToken: string,
        data?: any,
        config?: InternalAxiosRequestConfig
    ): Promise<R> {
        try {
            config = this.setupAuthorizationHeader(accessToken, config);
            return await axios.put(url, data, config);
        } catch (error) {
            return Promise.resolve(this.handleError(error));
        }
    }

    /**
    * Patch data to API
    * @param  {String} url Resource URI
    * @param  {String} accessToken Access Token
    * @param  {Object} data Request body data
    */
    public async patch<T = any, R = AxiosResponse<T>>(
        url: string,
        accessToken: string,
        data?: any,
        config?: InternalAxiosRequestConfig
    ): Promise<R> {
        try {
            config = this.setupAuthorizationHeader(accessToken, config);
            return await axios.patch(url, data, config);
        } catch (error) {
            return Promise.resolve(this.handleError(error));
        }
    }

    /**
    * Get data to API
    * @param  {String} url Resource URI
    * @param  {String} accessToken Access Token
    */
    public async get<T = any, R = AxiosResponse<T>>(
        url: string,
        accessToken: string,
        config?: InternalAxiosRequestConfig,
        needAuthorizationHeader: boolean = true
    ): Promise<R> {
        try {
            if (needAuthorizationHeader) {
                config = this.setupAuthorizationHeader(accessToken, config);
            }
            return await axios.get(url, config);
        } catch (error) {
            return Promise.resolve(this.handleError(error));
        }
    }

    public getAPIRequestConfigParams = (params: any) => {
        let config = baseAxios.defaults;
        config.params = params;

        return config;
    }

    /**
    * Handle error occurred during API call.
    * @param  {Object} error Error response object
    */
    private handleError(error: any): any {
        if (error.hasOwnProperty("response")) {
            return error.response;
        } else {
            return error;
        }
    }

    private setupAuthorizationHeader(accessToken: string,
        config?
    ): InternalAxiosRequestConfig {
        if (!config) {
            config = axios.defaults;
        }
        config!.headers!.Authorization = `Bearer ${accessToken}`;
        // config.headers["Authorization"] = `Bearer ${accessToken}`;
        return config!;
    }
}

const axiosJWTBrowserDecoratorInstance = new AxiosJWTBrowserDecorator();
export default axiosJWTBrowserDecoratorInstance;