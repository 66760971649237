export const customComboboxSearch = (usersObject, value) => {
  return usersObject.filter((a) =>
    a.displayName?.toLowerCase()?.startsWith(value?.toLowerCase())
  );
};

export const sortUsers = (users, selectedUserIds) => {
  const sortedUsers = users.sort((a1, a2) => {
    const isA1Selected = selectedUserIds.includes(a1.id);
    const isA2Selected = selectedUserIds.includes(a2.id);

    // If both a1 and a2 are either selected or unselected, sort alphabetically
    if (isA1Selected && isA2Selected) {
      return a1.displayName
        ?.toLowerCase()
        ?.localeCompare(a2.displayName?.toLowerCase());
    }
    // If a1 is selected but a2 is not, a1 should come first
    if (isA1Selected) return -1;
    // If a2 is selected but a1 is not, a2 should come first
    if (isA2Selected) return 1;

    // Otherwise, sort alphabetically for unselected items
    return a1.displayName
      ?.toLowerCase()
      ?.localeCompare(a2.displayName?.toLowerCase());
  });

  return sortedUsers;
};
