import { Spinner, MenuItem, MenuList } from "@fluentui/react-components";
import * as React from "react";
import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
// import StatusBar from "../../components/common/toast-notification/toast-notification";
import { ActivityStatus } from "../../models/activity-status";
import IGraphUser from "../../models/graph-user";
import IKonfiguration from "../../models/konfiguration/konfiguration";
import IToastNotification from "../../models/toast-notification";
import { IWithContext } from "../../providers/context-provider";
import KonfigurationAnwesenheit from "./konfiguration-anwesenheitsliste";
import KonfigurationEinladung from "./konfiguration-einladung";
import KonfigurationGremium from "./konfiguration-gremium";
import KonfigurationMitglieder from "./konfiguration-mitglieder";
import KonfigurationTagesordnung from "./konfiguration-tagesordnung";
import './konfiguration.scss';
import { LoaderMessage } from "../../constants/textLabels";
import { userHasPermission } from "../../Helpers/permissionHelper";
import PermissionsContext from "../context/permissions-context";

import * as microsoftTeams from "@microsoft/teams-js";
import KonfigurationCheckliste from "./konfiguration-checkliste";
import UnauthorizedPage from "../unauthorized-page/unauthorized-page";
import ConfirmDialog from "../common/dialog/confirm-dialog";

interface IKonfigurationState {
  initialKonfiguration: IKonfiguration;
  updatedKonfiguration: IKonfiguration;
  graphUsers: IGraphUser[];
  isLoading: boolean;
  notification: IToastNotification;
  activeSection: string;
  teamsContext: object;
  isSavingDialogOpen: boolean;
  previousSection: string;
  isKonfValid: boolean;
  dialogRef;
}

interface IKonfigurationProps
  extends WithTranslation,
  IWithContext,
  RouteComponentProps { }

class Konfiguration extends React.Component<
  IKonfigurationProps,
  IKonfigurationState
> {
  static contextType = PermissionsContext;
  context!: React.ContextType<typeof PermissionsContext>
  constructor(props: any) {
    super(props);
    this.state = {
      dialogRef: React.createRef(),
      isKonfValid: false,
      initialKonfiguration: {
        firmenname1: "",
        firmenname2: "",
        straße: "",
        plz: "",
        ort: "",
        typ: 1,
        mail: "",
        gremiumgröße: 1,
        hatSBV: false,
        hatJAV: false,
        einladungsvorlagen: [],
        tagesordnungspunktvorlagen: [],
      },
      updatedKonfiguration: {
        firmenname1: "",
        firmenname2: "",
        straße: "",
        plz: "",
        ort: "",
        typ: 1,
        mail: "",
        gremiumgröße: 1,
        hatSBV: false,
        hatJAV: false,
        einladungsvorlagen: [],
        tagesordnungspunktvorlagen: [],
      },
      graphUsers: [],
      isLoading: false,
      notification: { id: 0, message: "", type: ActivityStatus.None },
      activeSection: "gremium",
      teamsContext: {},
      isSavingDialogOpen: false,
      previousSection: "gremium",
    };
    sessionStorage.setItem("configDataChanged", "false");
  }

  activeComponentRef;

  async componentDidMount() {
    microsoftTeams.app.getContext().then((context: any) => {
      this.setState({ teamsContext: context });
    });
  }

  saveDataInChildComponent = async () => {
    if (this.activeComponentRef && this.activeComponentRef.onDataUpdate) {
      await this.activeComponentRef.onDataUpdate();
    }
  };

  handleTokenAccessFailure = (error: string) => {
    alert(error);
    this.props.history.push("/signin");
  };

  gremiumCallbackFunction = (childData) => {
    this.setState({
      updatedKonfiguration: {
        ...this.state.updatedKonfiguration,
        firmenname1: childData.firmenname1,
        firmenname2: childData.firmenname2,
        straße: childData.straße,
        plz: childData.plz,
        ort: childData.ort,
        mail: childData.mail,
        gremiumgröße: childData.gremiumgröße,
        hatSBV: childData.hatSBV,
        hatJAV: childData.hatJAV,
      },
    });
  };

  tagesordnungCallbackFunction = (childData) => {
    this.setState({
      updatedKonfiguration: {
        ...this.state.updatedKonfiguration,
      },
    });
  };

  navMenuItemClicked = (key) => {
    if (this.state.activeSection === "mitglieder" && !this.state.isKonfValid) {
      this.state.dialogRef.current.showConfirmDialog(
        "Die Konfiguration ist noch nicht vollständig. Sitzungen können nur angelegt werden, wenn alle Mitglieder ausgewählt sind.",
        async () => {
          this.setState({
            activeSection: key,
            previousSection: this.state.activeSection,
          });
        }
      );
    } else {
      this.setState({
        activeSection: key,
        previousSection: this.state.activeSection,
      });
    }
  };

  setIsKonfigurationValid = (valid: boolean) => {
    this.setState({ isKonfValid: valid })
  }

  renderKonfigurationSection = () => {
    const gremiumId = localStorage.getItem("selectedAuschuss")!
    if (
      !userHasPermission(
        this.context.permissionsArray,
        "GremiumConfigurationRead"
      )
    ) {
      // this.props.history.push("/unauthorizedpage");
      return;
    }
    switch (
    this.state.isSavingDialogOpen
      ? this.state.previousSection
      : this.state.activeSection
    ) {
      case "gremium":
        return (
          <KonfigurationGremium
            ref={(ref) => (this.activeComponentRef = ref)}
            userHasUpdatePermission={userHasPermission(
              this.context.permissionsArray,
              "GremiumConfigurationUpdate"
            )}
            navMenuItemClicked={this.navMenuItemClicked}
            auschuss={this.context.auschuss!}
            teamsContext={this.state.teamsContext}
          />
        );
      case "mitglieder":
        return (
          <KonfigurationMitglieder
            ref={(ref) => (this.activeComponentRef = ref)}
            userHasUpdatePermission={userHasPermission(
              this.context.permissionsArray,
              "GremiumConfigurationUpdate"
            )}
            konfigurationValid={this.setIsKonfigurationValid}
            navMenuItemClicked={this.navMenuItemClicked}
            teamsContext={this.state.teamsContext}
          />
        );
      case "einladung":
        return (
          <KonfigurationEinladung
            ref={(ref) => (this.activeComponentRef = ref)}
            userHasUpdatePermission={userHasPermission(
              this.context.permissionsArray,
              "GremiumConfigurationUpdate"
            )}
            navMenuItemClicked={this.navMenuItemClicked}
          />
        );
      case "tagesordnung":
        return (
          <KonfigurationTagesordnung
            gremiumId={gremiumId}
            ref={(ref) => (this.activeComponentRef = ref)}
            userHasUpdatePermission={userHasPermission(
              this.context.permissionsArray,
              "GremiumConfigurationUpdate"
            )}
            navMenuItemClicked={this.navMenuItemClicked}
          />
        );
      case "anwesenheit":
        return (
          <KonfigurationAnwesenheit
            ref={(ref) => (this.activeComponentRef = ref)}
            userHasUpdatePermission={userHasPermission(
              this.context.permissionsArray,
              "GremiumConfigurationUpdate"
            )}
            navMenuItemClicked={this.navMenuItemClicked}
          />
        );
      case "checkliste":
        return (
          <KonfigurationCheckliste
            ref={(ref) => (this.activeComponentRef = ref)}
            userHasUpdatePermission={userHasPermission(
              this.context.permissionsArray,
              "GremiumConfigurationUpdate"
            )}
            navMenuItemClicked={this.navMenuItemClicked}
          />
        );
      default:
        return (
          <KonfigurationGremium
            auschuss={this.context.auschuss!}
            ref={(ref) => (this.activeComponentRef = ref)}
            userHasUpdatePermission={userHasPermission(
              this.context.permissionsArray,
              "GremiumConfigurationUpdate"
            )}
            navMenuItemClicked={this.navMenuItemClicked}
            teamsContext={this.state.teamsContext}
          />
        );
    }
  };

  render = () => {
    return (
      userHasPermission(this.context.permissionsArray, 'GremiumRead') ?
        <div className="container-div Konfiguration-container">
          {/* <StatusBar notification={this.state.notification} /> */}
          <ConfirmDialog ref={this.state.dialogRef}></ConfirmDialog>
          {this.state.isLoading ? (
            <Spinner
              style={{
                width: "100%",
                height: "100vh",
                position: "fixed",
                top: "0",
                left: "0",
              }}
              className="loader"
              label={LoaderMessage}
            />
          ) : (
            <div className="">
              {/* z */}
              <div className="Konfiguration-content-wrapper">
                <div className="Konfiguration-navigation">
                  <MenuList style={{ width: 235, marginTop: 10 }}>
                    <MenuItem
                      onClick={() => this.navMenuItemClicked("gremium")}
                      className={`${this.state.activeSection === "gremium" && "menu-style"}`}
                    >
                      Gremium
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.navMenuItemClicked("mitglieder")}
                      className={`${this.state.activeSection === "mitglieder" ? "menu-style" : ""}`}
                    >
                      Mitglieder
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.navMenuItemClicked("tagesordnung")}
                      className={`${this.state.activeSection === "tagesordnung" ? "menu-style" : ""}`}
                    >
                      Tagesordnung und Protokoll
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.navMenuItemClicked("einladung")}
                      className={`${this.state.activeSection === "einladung" ? "menu-style" : ""}`}
                    >
                      Einladung
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.navMenuItemClicked("anwesenheit")}
                      className={`${this.state.activeSection === "anwesenheit" ? "menu-style" : ""}`}
                    >
                      Anwesenheit
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.navMenuItemClicked("checkliste")}
                      className={`${this.state.activeSection === "checkliste" ? "menu-style" : ""}`}
                    >
                      Checkliste Sitzung nachbereiten
                    </MenuItem>
                  </MenuList>
                </div>
                <div className="Konfiguration-content">
                  {this.renderKonfigurationSection()}
                </div>
              </div>
            </div>
          )}
        </div> : <UnauthorizedPage />
    );
  };
}

export default Konfiguration;
